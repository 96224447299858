// SideBar.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Search.css';
import '../GlobalStyles.css';
import { IoMdRefresh } from "react-icons/io";

const SideBar = ({
  sourceOptions,
  selectedSources,
  onSourceChange,
  orgOptions,
  selectedOrgs,
  onOrgChange,
  showOrgs,
  languageOptions,
  selectedLanguages,
  onLanguageChange,
  languageSearch = true,
  countryOptions,
  selectedCountries,
  onCountryChange,
  countrySearch = true,
  topicOptions,
  selectedTopics,
  onTopicChange,
  timeRangeOptions,
  selectedTimeRange,
  onTimeRangeChange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  dateError,
  selectedSemanticSearch,
  onSemanticSearchChange,
  customTrackerCreator,
  trackerName,
  onTrackerNameChange,
  trackerNameError,
  handleSearchClick,
  handleCreateTrackerClick,
  handleRefreshClick,
  sentimentOptions,
  selectedSentiments,
  onSentimentChange,
  showSentiment,
  clusterSortOptions,
  onClusterSortChange,
  selectedClusterSort,
  clusterSearch = false,
  personOptions,
  selectedPersons,
  onPersonChange,
  congressTracker = false,
  electionFactsTracker = false,
  selectedFactCheckType,
  onFactCheckChange,
  factCheckTypeOptions
}) => {
  // Determine if mobile and allow sidebar to be toggled on mobile.
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isMinimized, setIsMinimized] = useState(isMobile);
  const toggleSidebar = () => setIsMinimized(prev => !prev);

  // Helper for disabled button styling
  const getButtonStyle = (disabled) =>
    disabled ? { backgroundColor: '#ccc', color: '#666' } : {};

  return (
    <div className="sidebar fixed-width">
      {isMobile && (
        <button
          className="btn-sidebar btn-charcoal-gray mb-2"
          onClick={toggleSidebar}
          style={{ width: '100%' }}
        >
          {isMinimized ? 'Show Search Filters' : 'Hide Search Filters'}
        </button>
      )}

      {!isMinimized && (
        <>
          <div className="search-buttons">
            <button
              className="btn-circle btn-mid-gray mb-2 mr-1"
              onClick={handleRefreshClick}
              disabled={!clusterSearch && !!dateError}
              style={getButtonStyle(!clusterSearch && !!dateError)}
            >
              <IoMdRefresh />
            </button>
            <button
              className="btn-sidebar btn-charcoal-gray mb-2 mr-1"
              onClick={handleSearchClick}
              disabled={!clusterSearch && !!dateError}
              style={getButtonStyle(!clusterSearch && !!dateError)}
            >
              {customTrackerCreator ? 'Preview' : 'Search'}
            </button>
            {customTrackerCreator && (
              <button
                className="btn-sidebar btn-charcoal-gray mb-2"
                onClick={handleCreateTrackerClick}
                disabled={!!dateError || !!trackerNameError}
                style={getButtonStyle(!!dateError || !!trackerNameError)}
              >
                Create
              </button>
            )}
          </div>

          <div className="date-picker">
            {customTrackerCreator && (
              <>
                <input
                  type="text"
                  placeholder="Tracker Name"
                  value={trackerName}
                  onChange={onTrackerNameChange}
                  className="semantic-search-bar"
                />
                {trackerNameError && (
                  <div className="error-message">{trackerNameError}</div>
                )}
              </>
            )}
          </div>

          <div className="date-picker">
            <input
              type="text"
              placeholder="AI-Powered Search"
              value={selectedSemanticSearch}
              onChange={onSemanticSearchChange}
              className="semantic-search-bar"
            />
          </div>

          {showOrgs && (
            <div className="dropdown">
              <Select
                options={orgOptions}
                onChange={onOrgChange}
                value={selectedOrgs}
                isMulti
                isSearchable
                isClearable
                placeholder="Organizations"
              />
            </div>
          )}

          {!clusterSearch && (
            <div className="dropdown">
              <Select
                options={sourceOptions}
                onChange={onSourceChange}
                value={selectedSources}
                isMulti
                isSearchable
                placeholder="Sources"
                styles={{
                  menu: provided => ({ ...provided, overflowX: 'hidden' }),
                  option: provided => ({
                    ...provided,
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  })
                }}
              />
            </div>
          )}

          {languageSearch && !clusterSearch && (
            <div className="dropdown">
              <Select
                options={languageOptions}
                onChange={onLanguageChange}
                value={selectedLanguages}
                isMulti
                isSearchable
                placeholder="Languages"
              />
            </div>
          )}

          {countrySearch && (
            <div className="dropdown">
              <Select
                options={countryOptions}
                onChange={onCountryChange}
                value={selectedCountries}
                isMulti
                isSearchable
                isClearable
                placeholder={clusterSearch ? 'Country' : 'Countries'}
              />
            </div>
          )}

          {showSentiment && (
            <div className="dropdown">
              <Select
                options={sentimentOptions}
                onChange={onSentimentChange}
                value={selectedSentiments}
                isMulti
                isSearchable={false}
                placeholder="Sentiment"
              />
            </div>
          )}

          <div className="dropdown">
            <Select
              options={topicOptions}
              onChange={onTopicChange}
              value={selectedTopics}
              isMulti
              isSearchable
              placeholder="Topics"
            />
          </div>

          {congressTracker && (
            <div className="dropdown">
              <Select
                options={personOptions}
                onChange={onPersonChange}
                value={selectedPersons}
                isMulti
                isSearchable
                placeholder="Person"
              />
            </div>
          )}

          {electionFactsTracker && (
            <div className="dropdown">
              <Select
                options={factCheckTypeOptions}
                onChange={onFactCheckChange}
                value={selectedFactCheckType}
                isMulti
                isSearchable
                placeholder="Fact Check Subject"
              />
            </div>
          )}

          {!customTrackerCreator && (
            <>
              <div className="dropdown">
                <Select
                  options={timeRangeOptions}
                  onChange={onTimeRangeChange}
                  value={selectedTimeRange}
                  isSearchable={false}
                />
              </div>
              {selectedTimeRange &&
                selectedTimeRange.value === 'Custom Range' && (
                  <>
                    <div className="date-picker">
                      <input
                        type="text"
                        placeholder="Start: MM/DD/YYYY"
                        value={startDate}
                        onChange={onStartDateChange}
                        pattern="\d{2}/\d{2}/\d{4}"
                        title="Enter date in MM/DD/YYYY format"
                      />
                    </div>
                    <div className="date-picker">
                      <input
                        type="text"
                        placeholder="End: MM/DD/YYYY"
                        value={endDate}
                        onChange={onEndDateChange}
                        pattern="\d{2}/\d{2}/\d{4}"
                        title="Enter date in MM/DD/YYYY format"
                      />
                    </div>
                  </>
                )}
              {dateError && (
                <div className="error-message">{dateError}</div>
              )}
            </>
          )}

          {clusterSearch && (
            <div className="dropdown">
              <Select
                options={clusterSortOptions}
                onChange={onClusterSortChange}
                value={selectedClusterSort}
                isMulti={false}
                isSearchable={false}
                isClearable
                placeholder="Sort By"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SideBar;
