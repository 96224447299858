import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../GlobalStyles.css";
import {
  getDatePublished,
  getTimePublished,
  getTimezoneName,
} from "../../utils.js/dateHelpers";
import { HiMiniBarsArrowUp, HiMiniBarsArrowDown } from "react-icons/hi2";

export default function Timeline({ data,
  fringeCluster = false,
  chinaCluster = false,
  turkeyCluster = false,
  iranCluster = false,
  russiaCluster = false,
  usaCluster = false,
  hamasCluster = false,
  hezbollahCluster = false,
  isisCluster = false,
  bokoHaramCluster = false,
  alQaedaCluster = false,
  spaceForceCluster = false,
  utahCluster = false
}) {

  // Helper function that mirrors the cluster URL logic.
  const getClusterPath = () => {
    const defaultPath = 'global';
    return fringeCluster ? 'fringenarrative' :
      chinaCluster ? 'cn' :
        turkeyCluster ? 'tr' :
          iranCluster ? 'ir' :
            russiaCluster ? 'ru' :
              usaCluster ? 'us' :
                hamasCluster ? 'hamas' :
                  hezbollahCluster ? 'hezbollah' :
                    alQaedaCluster ? 'alqaeda' :
                      bokoHaramCluster ? 'bokoharam' :
                        isisCluster ? 'isis' :
                          spaceForceCluster ? 'sf' :
                            utahCluster ? 'ut' :
                              defaultPath;
  };
  // 1. Flatten and sort data by time
  const subNarrativeHistory = data[0]?.sub_narrative_history ?? [];
  function gatherEventsInChronOrder(subNarratives) {
    // Flatten all sub-narrative events into one list
    const allEvents = subNarratives.flatMap((sub) => sub.history);
    // Sort chronologically by timestamp
    return allEvents.sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
  }

  // 2. Format timestamp
  function formatTimestamp(originalTimestamp) {
    const isoString = originalTimestamp.replace(" ", "T") + "+00:00";
    const datePart = getDatePublished(isoString);
    const timePart = getTimePublished(isoString);
    const zonePart = getTimezoneName(isoString);
    return `${datePart}, ${timePart} (${zonePart})`;
  }

  const events = gatherEventsInChronOrder(subNarrativeHistory);

  // 3. Determine if mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 4. State for expanded source lists (desktop only)
  // The key is the event index and the value is a boolean (true if expanded)
  const [expandedEvents, setExpandedEvents] = useState({});
  const toggleExpanded = (idx) => {
    setExpandedEvents((prev) => ({ ...prev, [idx]: !prev[idx] }));
  };

  // 5. Common Styles
  const pageStyle = {
    minHeight: "100vh",
    padding: "1.5rem",
  };

  const timelineContainerStyle = {
    position: "relative",
    maxWidth: "80rem",
    margin: "0 auto",
  };

  // The center vertical line is shown only for desktop.
  const centerLineStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    borderRight: "2px solid #222222",
  };

  const cardStyle = {
    background: "white",
    borderRadius: "0.5rem",
    boxShadow:
      "0 4px 8px rgba(0,0,0,0.2), 0 -4px 8px rgba(0,0,0,0.1)",
    padding: "1rem",
    maxWidth: "30rem",
    position: "relative",
  };

  const timestampStyle = {
    fontSize: "1rem",
    color: "#6b7280",
    marginBottom: "0.5rem",
  };

  const descStyle = {
    fontWeight: 500,
    color: "#374151",
    marginBottom: "0.5rem",
  };

  const badgeContainerStyle = {
    marginTop: "0.75rem",
    display: "flex",
    flexWrap: "wrap",
    gap: "0.5rem",
    alignItems: "center",
  };

  const badgeStyle = {
    fontSize: "1rem",
    padding: "0.25rem 0.5rem",
    border: "1px solid #d1d5db",
    borderRadius: "9999px",
    background: "#f9fafb",
  };

  const toggleIconStyle = {
    cursor: "pointer",
    fontSize: "1.5rem", // Bigger icon size
    userSelect: "none",
    lineHeight: "1",
  };

  const centerDotWrapperStyle = {
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "2rem",
    height: "2rem",
    borderRadius: "9999px",
    border: "2px solid #d1d5db",
    background: "white",
  };

  const circleStyle = {
    width: "1rem",
    height: "1rem",
    borderRadius: "9999px",
    backgroundColor: "#CCCCCC",
  };

  // Desktop row style: alternating two-column layout.
  const rowStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "2rem 0",
  };

  // Mobile row style: a single-column, centered layout.
  const mobileRowStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem 0",
  };

  // For mobile, we adjust the card width.
  const mobileCardStyle = {
    ...cardStyle,
    maxWidth: "90%",
    marginTop: "1rem",
  };

  // Helper to render the card content (desktop only)
  function renderCardContent(event, idx) {
    // Determine if the event's sources are expanded
    const isExpanded = expandedEvents[idx] || false;
    // When collapsed, show at most 5 sources
    const sourcesToShow = event.IDs?.slice(
      0,
      isExpanded ? event.IDs.length : 5
    );
    return (
      <div style={cardStyle}>
        <p style={timestampStyle}>{formatTimestamp(event.timestamp)}</p>
        <p style={descStyle}>{event.desc}</p>
        <div style={badgeContainerStyle}>
          {sourcesToShow?.map((id, sourceIndex) => (
            <span key={id} style={badgeStyle}>
              <a
                href={`/article/${getClusterPath()}/${id}`}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {`Source ${sourceIndex + 1}`}
              </a>
            </span>
          ))}
          {/* Render toggle icon inline with the sources */}
          {event.IDs && event.IDs.length > 5 && (
            <span
              onClick={() => toggleExpanded(idx)}
              title={isExpanded ? "Show less" : "Show all"}
            >
              {isExpanded ? (
                <HiMiniBarsArrowUp style={toggleIconStyle} />
              ) : (
                <HiMiniBarsArrowDown style={toggleIconStyle} />
              )}
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={pageStyle}>
      <div style={timelineContainerStyle}>
        {/* Render center line only for desktop */}
        {!isMobile && <div style={centerLineStyle} />}

        {events.map((event, idx) => {
          // Skip events with no text
          if (event.desc === "No Text" || event.desc === "No Text.") return null;

          if (isMobile) {
            // Mobile: use a single-column layout with the center dot on top.
            // Cap the displayed sources to 3.
            return (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                style={mobileRowStyle}
              >
                <div style={centerDotWrapperStyle}>
                  <div style={circleStyle} />
                </div>
                <div style={mobileCardStyle}>
                  <p style={timestampStyle}>
                    {formatTimestamp(event.timestamp)}
                  </p>
                  <p style={descStyle}>{event.desc}</p>
                  <div style={badgeContainerStyle}>
                    {event.IDs?.slice(0, 3).map((id, sourceIndex) => (
                      <span key={id} style={badgeStyle}>
                        <a
                          href={`/article/${getClusterPath()}/${id}`}
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                        >
                          {`Source ${sourceIndex + 1}`}
                        </a>
                      </span>
                    ))}
                  </div>
                </div>
              </motion.div>
            );
          } else {
            // Desktop: alternating left/right layout.
            const isLeft = idx % 2 === 0;
            const halfWidthStyle = { width: "50%" };
            const leftCardStyle = {
              ...halfWidthStyle,
              display: "flex",
              justifyContent: "flex-end",
            };
            const rightCardStyle = {
              ...halfWidthStyle,
              display: "flex",
              justifyContent: "flex-start",
            };

            return (
              <motion.div
                key={idx}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4 }}
                style={rowStyle}
              >
                {isLeft ? (
                  <div style={leftCardStyle}>
                    {renderCardContent(event, idx)}
                  </div>
                ) : (
                  <div style={halfWidthStyle} />
                )}

                <div style={centerDotWrapperStyle}>
                  <div style={circleStyle} />
                </div>

                {!isLeft ? (
                  <div style={rightCardStyle}>
                    {renderCardContent(event, idx)}
                  </div>
                ) : (
                  <div style={halfWidthStyle} />
                )}
              </motion.div>
            );
          }
        })}
      </div>
    </div>
  );
}
