import React, { useState, useEffect, useRef } from "react";
import { Table, Badge } from "react-bootstrap";
import { useUser } from "../../contexts/UserContext";
import { useFirestore } from "../../contexts/FirestoreContext";
import { FaRegBookmark, FaBookmark, FaInfo } from "react-icons/fa";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle, FaSort } from 'react-icons/fa';
import { FaRegClone } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { RiShareBoxFill } from "react-icons/ri";
import CustomBadge from "../fixtures/CustomBadge";
import { FaBars } from "react-icons/fa6";
import './Search.css';
import '../GlobalStyles.css';
import { getDatePublished, getTimePublished, getTimezoneName } from "../../utils.js/dateHelpers"
import { timeAgo } from "./DataTableCluster";
import { HiMiniBarsArrowDown, HiMiniBarsArrowUp } from "react-icons/hi2";
import DOMPurify from 'dompurify';


function DataTable({
    data,
    semanticSearch,
    showIndex = true,
    countryColumn = true,
    languageColumn = true,
    showShareIcons = true,
    showSentiment = true,
    showCluster = true,
    showCharged = false,
    personOfInterest = null,
    orgOfInterest = null,
    orgsOfInterest = null,
    isInitialLoad = null,
    fringeCluster = false,
    chinaCluster = false,
    turkeyCluster = false,
    iranCluster = false,
    russiaCluster = false,
    usaCluster = false,
    hamasCluster = false,
    hezbollahCluster = false,
    isisCluster = false,
    bokoHaramCluster = false,
    alQaedaCluster = false,
    spaceForceCluster = false,
    utahCluster = false
}) {

    // Helper function that mirrors the cluster URL logic.
    const getClusterPath = () => {
        const defaultPath = 'global';
        return fringeCluster ? 'fringenarrative' :
            chinaCluster ? 'cn' :
                turkeyCluster ? 'tr' :
                    iranCluster ? 'ir' :
                        russiaCluster ? 'ru' :
                            usaCluster ? 'us' :
                                hamasCluster ? 'hamas' :
                                    hezbollahCluster ? 'hezbollah' :
                                        alQaedaCluster ? 'alqaeda' :
                                            bokoHaramCluster ? 'bokoharam' :
                                                isisCluster ? 'isis' :
                                                    spaceForceCluster ? 'sf' :
                                                        utahCluster ? 'ut' :
                                                            defaultPath;
    };

    const source_info_dict = {
        "7 News Belize": "A news outlet in Belize, known for providing comprehensive coverage of local and national news, including politics, business, and community events.",
        "A12.com": "A Brazilian digital media platform focused on Catholic content, offering news, articles, and videos that cater to the interests and values of the Catholic community in Brazil.",
        "ABC News.com": "The online platform of ABC News, offering news articles, videos, and live streaming, covering national and international news across a wide range of topics including politics, health, and entertainment.",
        "ABC Online": "The digital arm of the Australian Broadcasting Corporation, providing Australian and international news, analysis, and commentary across various platforms, including articles, podcasts, and video content.",
        "ANSA English": "The English-language service of Agenzia Nazionale Stampa Associata, Italy's leading wire service, offering comprehensive news coverage and updates on Italian and international events.",
        "AP News": "The digital interface of the Associated Press, a globally recognized news agency known for its factual and unbiased reporting on a wide array of topics including politics, economics, and global events.",
        "Africa": "A news and information website that provides coverage on a wide range of topics pertinent to Africa, including business, politics, technology, and culture, aimed at an international audience.",
        "Al Arabiya": "A leading Saudi-owned pan-Arab television news channel based in Dubai, known for providing news and current affairs programming primarily in Arabic, with a focus on the Middle East and the Arab world.",
        "Al Jazeera": "A Qatar-based international news organization that broadcasts globally, known for its comprehensive coverage of international news with a focus on the Middle East and North Africa.",
        "Al-Monitor": "An online media service based in Washington, D.C., providing in-depth coverage of the Middle East through reporting and analysis from a network of local journalists across the region.",
        "Al.com": "A regional news website that serves as a major digital news outlet for Alabama, offering local and statewide news on topics such as politics, education, and community events.",
        "Alabama": "A regional news website that serves as a major digital news outlet for Alabama, offering local and statewide news on topics such as politics, education, and community events.",
        "Anadolu Ajansi": "The Turkish state-run news agency, also known as Anadolu Agency, which provides news coverage on a variety of topics including politics, economics, and sports, both within Turkey and internationally.",
        "Ansa": "Italy's leading news agency, Agenzia Nazionale Stampa Associata, known for its comprehensive and timely reporting on Italian and international events across various media platforms.",
        "Arab News": "A Saudi Arabian English-language daily newspaper, known for its focus on news that affects the Arab world, offering perspectives on regional politics, social issues, and economic matters.",
        "Arirang": "A South Korean international broadcasting service that provides news, cultural programs, and educational content in English, aimed at an international audience to promote understanding of Korean culture and current affairs.",
        "Asahi Shimbun": "One of Japan's oldest and largest national newspapers, known for its credible journalism and comprehensive coverage of both domestic and international news.",
        "Australian Payment Plus": "A financial services provider in Australia.",
        "BBC Afrique": "The French-language service of the BBC World Service, offering news and information focused on Africa, with content that includes radio broadcasts, online news articles, and videos.",
        "BBC Arabic": "A part of the BBC World Service, providing news and information in Arabic through television, radio, and online platforms, focusing on the Arab world and broader international events.",
        "BBC Chinese": "The Chinese language service of the BBC, providing news, analysis, and features to Chinese-speaking audiences around the world, covering both international and regional topics.",
        "BBC Cymru Fyw": "A Welsh language digital news service provided by the BBC, offering news, analysis, and features that focus on Wales and its communities, accessible through various multimedia formats.",
        "BBC Mundo": "The Spanish-language service of the BBC, providing news and analysis on global and regional events, aimed at Spanish-speaking audiences around the world.",
        "BBC News": "The news and current affairs division of the British Broadcasting Corporation, known for its comprehensive global news coverage, impartial reporting, and diverse programming across television, radio, and online platforms.",
        "BBC Portuguese": "The Portuguese language service of the BBC, offering news and information primarily focused on Brazil and Portuguese-speaking countries, along with global events, through online and radio formats.",
        "BBC Sport": "A division of the BBC that provides comprehensive coverage of sports news, events, and analysis across various platforms including television, radio, and online.",
        "BBC UK China": "The simplified Chinese section of the BBC website, catering to Mandarin-speaking audiences with news and information about the UK and international events, presented in simplified Chinese characters.",
        "Bbc News Japan": "The Japanese-language section of the BBC website, offering news, analysis, and features on both international and regional topics, targeted at Japanese-speaking audiences.",
        "Bbc.com/russian": "The Russian-language service of the BBC, providing news, analysis, and multimedia content on global and regional issues, aimed at Russian-speaking audiences worldwide.",
        "Belta": "The Belarusian Telegraph Agency, a state-owned news agency in Belarus, known for distributing news and information in Belarusian, Russian, and English, focusing on national and international events from the government's perspective.",
        "Better Investing": "A non-profit organization that provides education and tools for individual investors and investment clubs, focusing on teaching effective methods for long-term stock investing.",
        "Bloomberg": "A global financial news and media company known for its comprehensive coverage of financial markets, economics, and business news, providing data, analysis, and news through various platforms.",
        "Boise Cascade Company": "An American manufacturer and distributor of building materials, known for its production of engineered wood products, plywood, lumber, and a range of other construction materials.",
        "Borneo Bulletin": "The national English-language daily newspaper of Brunei, known for its coverage of local news, business, and community events, along with regional and international news.",
        "Business Insider US": "A financial and business news website known for its aggressive journalism and frequent updates on finance, media, technology, and other industry verticals, targeting professionals and consumers alike.",
        "CBC": "Canada's national public broadcaster, offering a wide range of news, information, and entertainment in English and French across television, radio, and digital platforms.",
        "CNBC": "An American cable, satellite, and internet television channel that specializes in business news and financial market coverage, also known for its live market analysis during trading hours.",
        "Calvari": "placeholder",
        "Cardinal News": "A regional news outlet that focuses on in-depth reporting and analysis of issues affecting parts of Virginia, USA, with an emphasis on comprehensive local journalism.",
        "Cbs News": "The news division of the American television and radio network CBS, offering a range of news programming and in-depth reports.",
        "China Daily ": "An English-language daily newspaper owned by the Publicity Department of the Communist Party of China, offering news and commentary with a focus on China's perspective on international issues and domestic affairs.",
        "Clarin": "A leading Argentine daily newspaper, known for its wide circulation and influence, covering national and international news, sports, and entertainment, with a centrist to conservative editorial stance.",
        "Cnbc": "An American cable, satellite, and internet television channel that specializes in business news and financial market coverage, also known for its live market analysis during trading hours.",
        "Colorado Springs Gazette": "A daily newspaper based in Colorado Springs, Colorado, known for its local and regional news coverage, including politics, sports, and community events.",
        "Corriere Della Sera": "One of Italy's oldest and most reputable newspapers, known for its focus on headline news, culture, and political coverage, with a centrist editorial stance.",
        "Ctv": "The news division of the Canadian Television Network (CTV), known for providing comprehensive national and international news coverage, as well as regional news across Canada.",
        "D it": "One of Italy's leading newspapers, which provides extensive coverage of national and international news, politics, economics, and culture, with an emphasis on in-depth articles and multimedia content.",
        "DW": "The Polish language service of Deutsche Welle, Germany's international broadcaster, offering news, analysis, and insights on global and regional events, tailored to Polish-speaking audiences.",
        "DW AR": "The Arabic language service of Deutsche Welle, Germany's international broadcaster, providing news, reports, and analyses focusing on Germany, Europe, and international events from a German perspective for Arabic-speaking audiences.",
        "DW ES": "The Spanish language service of Deutsche Welle, Germany’s international broadcaster, offering news and information on global and European issues with a focus on catering to Spanish-speaking audiences.",
        "DW FR": " The French language service of Deutsche Welle, Germany's international broadcaster, providing news, analysis, and features on global and European topics for French-speaking audiences.",
        "DW África": "A service of Deutsche Welle, Germany’s international broadcaster, which provides news and information in several African languages, focusing on issues relevant to the African continent and offering a European perspective.",
        "Daily Mail and Guardian": "A South African weekly newspaper, known for its investigative journalism and comprehensive coverage of South African and international news, politics, and current affairs.",
        "Daily News": "A regional news outlet covering sports, entertainment, and community events in the Greater Los Angeles area.",
        "Daily Sabah": "A Turkish daily newspaper published in English, known for its pro-government stance and coverage of national and international news, including politics, economy, and culture.",
        "Der Spiegel": "A leading German news magazine known for its in-depth investigative journalism and critical analysis of both domestic and international issues, with a strong emphasis on political and social topics.",
        "Deutsche Welle": "Germany's international broadcaster that provides news, information, and cultural content in multiple languages to audiences around the world, promoting understanding of Germany and the European perspective.",
        "Die Zeit": "A German weekly newspaper that offers detailed analysis and coverage of politics, culture, and current affairs, recognized for its comprehensive journalism and extensive commentary.",
        "Dw Brasil": "The Brazilian service of Deutsche Welle, providing news and information in Portuguese, focusing on international perspectives as well as specific issues relevant to Brazil and Latin America.",
        "Dw RU": "The Russian language service of Deutsche Welle, Germany’s international broadcaster, offering news, analysis, and commentary on global events with a focus on providing a German perspective to Russian-speaking audiences.",
        "EDP Nutrition": "A French website focused on providing information and resources related to dietetics and nutrition, catering primarily to professionals in the field of health and nutrition.",
        "ET News": "A South Korean media outlet specializing in technology and electronics news, offering in-depth coverage of the latest trends, innovations, and industry developments in South Korea and internationally.",
        "El Universal": "One of Mexico's major daily newspapers, known for its comprehensive coverage of national and international news, politics, and culture, with a history of editorial independence.",
        "El Universal Venezuela": "A prominent Venezuelan newspaper that provides extensive coverage of national news, politics, and economic issues, known for its critical stance on government policies and developments within Venezuela.",
        "Ernst&Young Polska": "The Polish branch of Ernst & Young, a global professional services firm, providing audit, tax, consulting, and advisory services to businesses in Poland.",
        "Estadão": "One of Brazil's leading newspapers, known for its editorial independence and comprehensive coverage of national and international news, politics, and economic issues.",
        "Excelsior": "A major daily newspaper in Mexico, recognized for its wide-ranging news coverage that includes politics, culture, and sports, with a history that contributes to its reputation as a trusted source of information.",
        "Ey (pl)": "The Polish website of Ernst & Young, providing localized information on professional services such as auditing, tax, consulting, and advisory, tailored for the Polish market.",
        "Ey": "The official global website of Ernst & Young (EY), one of the largest professional services networks in the world, offering audit, consulting, tax, and advisory services to its international clientele.",
        "FactCheck.org": "A non-partisan, nonprofit website that aims to reduce the level of deception and confusion in U.S. politics by analyzing and verifying factual accuracy in political discourse and media reporting.",
        "Folha de S.Paulo": "One of Brazil's largest and most influential newspapers, known for its comprehensive coverage of national and international news, politics, business, and culture, with a commitment to investigative journalism.",
        "Forbes": "An American business magazine known for its coverage of financial, investment, and marketing topics, as well as for its lists and rankings of the wealthiest Americans and largest companies.",
        "Forbes Colombia": "The Colombian edition of Forbes magazine, focusing on business, finance, investing, and wealth, providing insights and analysis tailored to the Colombian and Latin American markets.",
        "Foreign Affairs": "An American magazine of international relations and U.S. foreign policy, known for its in-depth analysis and discussion of global affairs, authored by experts in the fields of politics, economics, and international relations.",
        "Foreign Policy": "A magazine and website dedicated to global affairs, current events, and domestic and international policy, known for providing insightful and influential analysis on complex foreign policy issues.",
        "Fox News": "A conservative-leaning cable and satellite news television channel in the U.S., known for its right-of-center political commentary and programming.",
        "France 24": "An international news and current affairs television network based in France, broadcasting 24/7 in French, English, Arabic, and Spanish, focusing on international news from a French perspective.",
        "France 24 fr": "The French-language service of France 24, providing around-the-clock news coverage and analysis on global events with a focus on perspectives from France and Europe.",
        "France24.com AR": "The Arabic-language version of France 24’s website, offering continuous news coverage and analysis of global and regional events from a French perspective, tailored for Arabic-speaking audiences.",
        "France24.com/es": "The Spanish-language service of France 24, offering 24/7 news coverage and analysis on international events, with a focus on providing perspectives relevant to Spanish-speaking audiences.",
        "Ge Rh Expert": "temp",
        "Global Times": "A Chinese English-language newspaper under the People's Daily, known for its nationalist tone and focus on international issues from a Chinese government perspective.",
        "Global Voices": "An international, multilingual community of writers, bloggers, and digital activists that curates, verifies, and translates news stories from social media and independent sources around the world, focusing on voices that are not typically heard in international mainstream media.",
        "Globo": "A major Brazilian media network, one of the largest in Latin America, known for its television broadcasting, news coverage, and entertainment programming, significantly influencing Brazilian culture and public opinion.",
        "Gmina Kamienica": "temp",
        "Gospodarka": "A Polish news portal that focuses on economic, financial, and business news, providing analysis and information primarily tailored to professionals and enthusiasts interested in Poland's economy and business environment.",
        "Haaretz": "An Israeli newspaper known for its liberal stances and in-depth coverage of domestic and international news, often providing critical perspectives on Israeli policies and social issues.",
        "Hindustan Times": "One of India's leading English-language daily newspapers, known for its comprehensive coverage of national news, international affairs, and local events across multiple platforms.",
        "Hmrn.ru": "A Russian regional news website that focuses on delivering local news, updates, and information specific to the city of Khanty-Mansiysk and surrounding areas in Russia.",
        "Hurriyet Daily News": "An English-language daily newspaper in Turkey, known for providing news and analysis on Turkish and regional affairs, catering to an international audience interested in Turkish news from a secular perspective.",
        "I24News": "The French-language section of the i24 News website, offering continuous coverage and analysis of international and regional news with a particular focus on the Middle East, broadcast from Israel.",
        "I24news ar": "The official website of i24 News, an international news network based in Israel, providing news coverage and analysis in English, French, and Arabic, with a focus on the Middle East and global affairs.",
        "Il Sole 24 Ore": "Italy's leading financial and economic newspaper, known for its in-depth coverage of financial markets, economic news, and business-related information, serving professionals and decision-makers in Italy.",
        "Impact.forsal.pl": "A section of the Polish financial news website Forsal.pl, dedicated to in-depth analysis and reporting on business, technology, and economic impact stories, focusing on both domestic and international markets.",
        "Indian Express": "An Indian daily newspaper known for its serious journalism, providing thorough coverage of national news, politics, and cultural issues, with a reputation for investigative reporting and a critical approach to news.",
        "Indiatimes": "A major Indian news portal that provides a wide array of news, entertainment, lifestyle, and sports content, catering mainly to the urban population with a focus on trending and viral stories",
        "Infobae": "An Argentine digital news outlet known for its 24/7 coverage of national and international news, sports, and entertainment, appealing to a broad audience with its multimedia approach.",
        "Israel Hayom": "A free Israeli daily newspaper, known for its pro-government stance and support of conservative policies, it is one of the most widely circulated newspapers in Israel, covering news, politics, and culture.",
        "Jornal O Globo": "A prominent Brazilian newspaper known for its detailed reporting on national politics, economics, and culture, widely respected for its investigative journalism and comprehensive coverage of both Brazilian and international news.",
        "Jornalvozativa.com": "A Brazilian news website that provides local news, cultural events, and community updates, focusing on the interests and concerns of its regional audience.",
        "KBS WORLD Radio": "The international broadcasting service of Korea Broadcasting System (KBS), providing news, cultural programs, and language services in multiple languages, aimed at promoting understanding of Korea's culture and affairs globally.",
        "KBS World Radio French Service": "A segment of Korea's international broadcasting service that offers programs and news in French, focusing on delivering information about South Korea's culture, society, and current events to French-speaking audiences worldwide.",
        "Kbs": "The official website of the Korea Broadcasting System (KBS), South Korea's national public broadcaster, offering a wide range of content including news, entertainment, and cultural programming in Korean.",
        "Khaama Press": "An independent news agency based in Afghanistan, known for its timely and detailed coverage of local news, politics, and international affairs affecting the region.",
        "Knews Media": "A digital news platform that focuses on delivering up-to-date news and analysis on current events, potentially catering to a specific regional or national audience. Details about its specific focus or region of coverage are not widely known.",
        "Kommersant": "A leading Russian daily newspaper and media company, known for its comprehensive coverage of business, politics, and cultural affairs, with a reputation for detailed and independent journalism.",
        "L'Express": "A French weekly news magazine, known for its investigative reporting and in-depth analysis of politics, economics, and culture, catering to a broad French-speaking audience interested in current affairs.",
        "La Nacion": "A major Argentine newspaper, recognized for its conservative editorial stance and extensive coverage of national politics, economy, and culture, as well as international news.",
        "La Repubblica": "One of Italy's leading daily newspapers, known for its center-left editorial stance and comprehensive coverage of news, politics, culture, and science, often featuring investigative journalism and in-depth reports.",
        "Labour": "The digital platform for John Wiley & Sons, providing access to a vast collection of academic and scientific research, including journals, books, and reference works across multiple disciplines.",
        "Le Figaro": "One of France's oldest and largest national newspapers, known for its conservative stance and comprehensive coverage of French and international news, politics, and culture.",
        "Le Monde (Blogs)": "A section of the Le Monde website that features a variety of blogs written by journalists and guest writers, offering personal insights and commentary on current events, culture, science, and more, extending the newspaper's traditional coverage.",
        "Le Monde": "A leading French daily newspaper known for its thorough coverage of both domestic and international news, offering in-depth analysis and a center-left editorial perspective.s",
        "Lead Stories": "A fact-checking website that uses the 'Trendolizer' engine to detect the most trending stories and rumors on the internet to debunk false news and confirm factual stories, particularly focusing on claims that are viral on social media.",
        "Los Angeles Times": "A major daily newspaper based in Los Angeles, California, known for its coverage of issues particularly relevant to the Western United States.",
        "MSNBC": "An American cable news channel that provides political commentary and news coverage, known for its progressive leanings and focus on U.S. politics and current events.",
        "Mainichi": "The online portal for the Mainichi Shimbun, one of Japan’s oldest and most prestigious daily newspapers, known for its comprehensive coverage of domestic and international news, culture, and sports.",
        "Mainichi Japan": "The online portal for the Mainichi Shimbun, one of Japan’s oldest and most prestigious daily newspapers, known for its comprehensive coverage of domestic and international news, culture, and sports.",
        "Market Watch": "A financial information website that provides news, analysis, and stock market data, known for its real-time market updates and insights into economic and business trends.",
        "Milenio": "A Mexican multimedia company known for its daily newspaper and television news channel, offering extensive coverage of national news, politics, and cultural affairs, with a focus on in-depth analysis and regional news.",
        "NBC News": "The news division of the American broadcast television network NBC, known for national news coverage alongside its local affiliate news networks.",
        "Nacion": "The online portal for La Nación, a major Costa Rican newspaper known for its detailed coverage of national and international news, politics, and cultural affairs, serving as a key source of information for the Costa Rican public.",
        "National Democratic Institute": "The official website of the National Democratic Institute, a non-profit organization that works internationally to support and strengthen democratic institutions, promote citizen participation, enhance transparency, and foster political cooperation.",
        "National Post": "The online presence of the National Post, a Canadian English-language newspaper, known for its detailed coverage of news, politics, and finance, with a conservative editorial stance.",
        "Ndtv": "An Indian news media company known for its comprehensive broadcast and online news coverage, focusing on Indian and international affairs, and recognized for its commitment to journalism and innovation in news delivery.",
        "New York Observer": "A publication known for its focus on New York City's culture, real estate, media, politics, and the entertainment industry, providing in-depth analysis and commentary with a unique perspective on metropolitan life.",
        "News24": "A South African digital news platform offering breaking news and in-depth coverage of local and international events, including politics, sports, and entertainment.",
        "Nhk World": "The international broadcasting service of Japan’s public broadcaster NHK, providing news and information in English and other languages, featuring Asian perspectives on global events.",
        "Npr": "A non-profit media organization that serves as a national syndicator to a network of public radio stations in the United States, known for its in-depth public affairs and cultural programming.",
        "Nuclear Energy Agency": "An intergovernmental agency organized under the OECD that facilitates cooperation among countries with advanced nuclear technology infrastructures to improve safety, technology, and environmental practices.",
        "Pagina 12": "An Argentine daily newspaper known for its progressive stance, focusing on political, cultural, and human rights issues, often providing in-depth investigative journalism.",
        "Palestine News Network": "A Palestinian news agency that reports on political, cultural, and societal issues from a Palestinian perspective, aiming to provide comprehensive coverage of events in Palestine and the broader Middle East.",
        "Podatki Gazeta Prawna": "A section of the Polish legal and economic newspaper 'Gazeta Prawna' that focuses on taxation, providing updates, analysis, and advice on tax-related matters in Poland.",
        "PolitiFact": "An American fact-checking website that rates the accuracy of claims by elected officials and others on its Truth-O-Meter, aiming to improve public understanding of factual political discourse.",
        "Politico": "An American political journalism company that covers politics and policy in the United States and internationally, known for its detailed coverage of politics and its impact on policy.",
        "Powiem Polsce": "A Polish news outlet that delivers news and analysis on current events, politics, and society, catering primarily to a Polish-speaking audience.",
        "Ra Electronic music online": "An online platform dedicated to electronic music, featuring news, reviews, and events related to the electronic music scene worldwide.",
        "Reforma": "One of Mexico's major newspapers, known for its independent editorial policy and extensive coverage of national and international news, politics, and culture.",
        "Reuters": "An international news organization owned by Thomson Reuters, noted for its focus on business and financial news along with general news.",
        "Richmond": "The online portal for the Richmond Times-Dispatch, providing news, sports, business, and entertainment coverage for Richmond, Virginia, and the surrounding area.",
        "Riyadh Daily": "An English-language daily newspaper in Saudi Arabia, offering news and insights on national and international affairs, economics, and social issues from a Saudi perspective.",
        "Sabc News": "The news division of the South African Broadcasting Corporation, offering comprehensive coverage of local and international news across television, radio, and online platforms.",
        "Saudi Gazette": "An English-language daily newspaper published in Saudi Arabia, focusing on news from Saudi Arabia and the world, including business, society, and religion.",
        "Sior": "A global professional organization that represents commercial real estate professionals with expertise in industrial and office markets, providing resources, education, and networking opportunities.",
        "Sky News": "A British news and multimedia broadcasting service known for its 24-hour news coverage on television, online, and radio, offering a wide range of news from the UK and around the world.",
        "Snopes": "An American fact-checking website that investigates myths, rumors, and misinformation to provide accurate and detailed clarifications, often focusing on viral content.",
        "South China Morning Post": "A Hong Kong-based English-language newspaper providing in-depth reports on China and Asia, known for its comprehensive coverage of regional political, economic, and social issues.",
        "Sydney Morning Herald": "An Australian newspaper published in Sydney, known for its award-winning journalism and coverage of local, national, and international news.",
        "Süddeutsche Zeitung": "One of Germany's largest daily newspapers, noted for its investigative journalism and detailed analysis of national and international events.",
        "TA Associates": "A global growth private equity firm, providing capital to companies within technology, healthcare, financial services, consumer, and business services industries to foster growth.",
        "TASS Russian News Agency": "A major Russian state-owned news agency, one of the largest in the country, offering wide-ranging coverage of international and domestic news events.",
        "Te Connectivity": "A global technology and manufacturing company specializing in connectivity and sensor solutions for a variety of industries, including automotive, industrial equipment, and aerospace.",
        "The Atlantic": "An American magazine and multi-platform publisher that provides in-depth analysis of news in politics, business, technology, health, culture, and more, known for its thoughtful editorial stance.",
        "The Citizen": "An English-language daily newspaper in Tanzania, providing news and insights on national, regional, and international affairs, with a focus on East African perspectives.",
        "The Globe and Mail": "Canada’s nationally distributed newspaper, offering extensive coverage of national, international, and business news from its headquarters in Toronto.",
        "The Japan Times Online": "An English-language newspaper in Japan, providing domestic and international news, with a focus on Japanese perspectives on social and economic issues.",
        "The Jerusalem Post": "An Israeli English-language daily newspaper, known for its coverage of Israel, the Middle East, and the Jewish world.",
        "The Korea Times": "South Korea's oldest English-language newspaper, offering comprehensive coverage of both domestic and international affairs.",
        "The Times of India": "One of India’s largest English-language daily newspapers, known for its wide coverage of news, business, entertainment, and sports.",
        "The Times of Israel": "An English-language online newspaper and news website based in Jerusalem, offering news and analysis on Israel, the Middle East, and Jewish world affairs.",
        "The Water Network": "An online platform dedicated to improving global water management and security, providing resources, news, and collaboration opportunities for water professionals worldwide.",
        "Time": "An iconic American weekly news magazine known for its coverage of world events, influential profiles, and in-depth feature articles.",
        "Today": "The online platform for NBC's Today show, providing news, lifestyle articles, videos, and segments from the morning show, covering a broad range of topics including health, entertainment, and consumer advice.",
        "UOL ECOA": "A Brazilian platform within the UOL portal focused on social issues, sustainability, and human rights, providing articles and insights that promote positive impacts in society.",
        "UOL Esporte": "The sports section of UOL (Universo Online), one of Brazil's largest internet portals, offering comprehensive coverage of national and international sports news and events.",
        "United States Institute of Peace": "An independent federal institution founded by Congress to help prevent, mitigate, and resolve violent conflicts around the world through nonviolent means.",
        "Uol": "One of the largest Brazilian web portals, providing a wide range of content including news, entertainment, sports, and services like email, search, and shopping.",
        "Usa Today": "An American daily newspaper known for its straightforward news coverage and widespread distribution across the United States.",
        "Virginia Mercury": "An independent, nonprofit online news site providing in-depth reporting on Virginia’s public policy, government, politics, and environmental issues.",
        "Voa News": "The official external broadcast institution of the United States federal government, providing news and information in multiple languages to an international audience.",
        "Vox": "An American news website that aims to explain news and topics in public discourse with in-depth analysis, focusing on politics, public policy, world affairs, pop culture, science, and business.",
        "Wall Street Journal": "An American business-focused, English-language international daily newspaper known for its comprehensive coverage of financial and economic news.",
        "Washington Post": "A highly respected American daily newspaper known for its in-depth political reporting and investigative journalism.",
        "Xinhua": "The English-language version of Xinhua News Agency's official website, providing news and information on Chinese political, economic, and cultural affairs, as well as international events.",
        "Xinhua Net": "The English-language version of Xinhua News Agency's official website, providing news and information on Chinese political, economic, and cultural affairs, as well as international events.",
        "Xinhuanet China": "The official website of Xinhua News Agency, China's state-run news organization, offering comprehensive news coverage in multiple languages, including news on Chinese domestic policies and global affairs.",
        "Xinhuanet(新华网)": "Often associated with Xinhua News Agency, this domain serves as a portal for accessing news content in Chinese, covering a wide range of topics from politics and economics to culture and sports within China and globally.",
        "Yahoo Entertainment": "A section of Yahoo that provides news, articles, and videos focusing on celebrities, movies, music, and all aspects of the entertainment industry.",
        "Yahoo Tech": "A Yahoo platform offering the latest news and insights on technology, including gadgets, software updates, technological breakthroughs, and industry trends.",
        "Yahoo! Finance": "A leading source for financial news, data, and commentary, including stock quotes, up-to-date news, portfolio management resources, and international market data.",
        "Yahoo! Lifestyle": "A Yahoo section that covers health, beauty, fashion, travel, and family life, providing advice, news, and features to help readers improve and enjoy their everyday lives.",
        "Yahoo! News US": "The U.S. news platform within Yahoo that aggregates news from various sources, offering a broad spectrum of domestic and international news stories.",
        "Ynetnews": "The English-language Israeli news website of Yedioth Ahronoth, Israel’s most widely-read daily newspaper, offering comprehensive news coverage and analysis on Israel and the Middle East.",
        "Yonhap News": "South Korea’s largest news agency, providing a wide range of news services including national, political, economic, cultural, and international news, primarily from a South Korean perspective.",
        "e-magazyny.pl": "A Polish platform offering access to a variety of digital magazines covering topics such as lifestyle, business, technology, and health, catering to diverse reader interests.",
        "geex.x-kom.pl": "A Polish tech blog hosted by x-kom, providing reviews, news, and analysis on the latest in consumer electronics, gaming, and technology trends.",
        "homeandmarket.eu": "A European-based online magazine that focuses on lifestyle, home decor, and market trends, offering insights and advice for modern living and business practices.",
        "horecabc.pl": "A Polish website dedicated to the hospitality industry, offering news, trends, and resources for professionals in hotels, restaurants, and related sectors.",
        "i24": "An international news network based in Israel, broadcasting in English, French, and Arabic, and known for providing a mix of live news reports and analysis on global and regional events with a focus on the Middle East.",
        "kapitalpolski": "A Polish financial news website that covers economic, financial, and business news, providing analysis and information aimed at investors and professionals in Poland.",
        "puszczamyztorbami.pl": "A Polish lifestyle blog that focuses on travel, with personal stories, tips, and guides for traveling both domestically and internationally.",
        "tvrepublika.pl": "A Polish news and opinion website that offers video-based content with a conservative perspective, focusing on national politics, culture, and societal issues.",
        "www.polskifitness.tv": "A Polish website dedicated to fitness, offering workout videos, health tips, and nutritional advice to help viewers maintain a healthy lifestyle.",
        "Российская газета": "A Russian government-owned newspaper of record which publishes official decrees, statements, and documents of state bodies, in addition to covering news across Russia with an emphasis on government perspectives.",
        "العربية.نت (Al Arabiya)": " provides comprehensive news coverage, focusing on the Arab world and broader international events, with content primarily in Arabic.",
        "شبكة فلسطين الاخبارية": "A Palestinian news agency that reports on political, cultural, and societal issues from a Palestinian perspective, aiming to provide comprehensive coverage of events in Palestine and the broader Middle East.",
        "在线报导| DW": "The Chinese language service of Deutsche Welle, offering news and information about Germany and international events from a German perspective, catering to Chinese-speaking audiences.",
        "新华网": "The official website of Xinhua News Agency, China's state-run news organization, offering comprehensive news coverage in multiple languages, including news on Chinese domestic policies and global affairs.",
        "연합뉴스": "South Korea’s largest news agency, providing a wide range of news services including national, political, economic, cultural, and international news, primarily from a South Korean perspective.",
        "MeriTalk": "A public-private partnership that provides news and analysis on government IT initiatives, emphasizing collaboration between the public and private sectors in the field of technology.",
        "Roll Call": "A newspaper and website that covers the U.S. Congress, offering detailed reporting on legislative activities, congressional policy debates, and the political landscape in Washington, D.C.",
        "The Hill": "A political news website and newspaper that publishes Congress, politics, political campaigns, and international relations news, focusing on the United States federal government.",
        "FedScoop": "A government tech news organization that reports on and analyzes technology initiatives within the U.S. federal government, focusing on how technology is transforming federal agencies.",
        "Government Executive": "An online publication that provides federal executives with news, analysis, and guidance essential to managing U.S. government agencies, focusing on the federal workforce and policy developments.",
        "Executive Gov": "A news source that reports on the U.S. government's executive branch and its leaders, offering insights into policy decisions and their implications on various sectors.",
        "Federal News Radio": "A broadcasting and digital media platform dedicated to providing news, information, and analysis on the federal government's operations and employees.",
        "Nextgov": "An informational resource that explores how technology and innovation impact federal agencies, offering news and analysis on IT policy, emerging tech trends, and government IT management.",
        "Federal Times": "A website and publication focusing on the business of the federal government, offering news and analysis on management, technology, and workforce issues affecting federal agencies and their employees.",
        "FEDweek": "A digital news service providing information and advice to U.S. federal employees and retirees on topics such as benefits, retirement planning, and policy changes affecting the federal workforce.",
        "Real Clear Politics": "A political news site and polling data aggregator that covers election campaigns, politics, and policy discussions, known for its comprehensive collection of political analysis and commentary.",
        "Small Business Administration": "A U.S. government agency that provides support to entrepreneurs and small businesses, offering resources on starting, managing, and growing businesses, as well as loan and grant information.",
        "SmallGovCon": "A legal blog that offers insights, news, and commentary on government contracting issues affecting small businesses, focusing on regulations, litigation, and strategies for compliance.",
        "Bizjournals": "A network of local business newspapers providing coverage of regional business news, industry trends, and economic insights across multiple cities in the United States.",
        "GovCon Wire": "A digital media site that provides timely and informative news about the business of government contracting, focusing on the latest contract awards, executive moves, and industry events.",
        "CNET": "A website that provides product reviews, news, prices, videos, forums, how-tos, and more on technology and consumer electronics.",
        "National Geospatial-Intelligence Agency": "A U.S. government agency responsible for collecting, analyzing, and distributing geospatial intelligence in support of national security.",
        "Technology Review": "Published by MIT, this magazine and website covers emerging technologies and their impact on business and society.",
        "Fast Company": "A monthly American business magazine that publishes articles on technology, business, and design, focusing on innovation in technology, leadership, world changing ideas, creativity, and design.",
        "Ars Technica": "A technology news and information website that publishes news, reviews, and guides on technology, science, policy, and gaming.",
        "Dark Reading": "A cybersecurity news site that provides in-depth information about IT security, data protection, and vulnerability analysis for IT professionals.",
        "Wired": "A monthly magazine that reports on how emerging technologies affect culture, the economy, and politics.",
        "Techcrunch": "A leading technology media property, dedicated to obsessively profiling startups, reviewing new Internet products, and breaking tech news.",
        "SciTech Daily": "A source for the latest science news, covering new discoveries in science, technology, and health.",
        "Tech Xplore": "A digital publication covering the latest engineering, electronics, and technology advances.",
        "The Record Media": "A news publication that covers cybersecurity, privacy, and government affairs, focusing on in-depth analysis of the tech industry.",
        "Gizmodo": "A technology and science fiction website that covers topics such as gadgets, electronics, science, technology, and culture.",
        "Quartz": "A business-focused English-language international news website that publishes news stories on global economic topics and general news.",
        "Scientific American": "A popular science magazine that features articles and commentary on developments in science and technology.",
        "National Geographic": "A magazine known for its in-depth articles about nature, science, culture, and geography, with stunning photography.",
        "Air & Space Forces Magazine": "A publication dedicated to covering the latest news on the U.S. Air Force and Space Force, including technology, operations, and policies.",
        "Association of the United States Army": "An organization that supports U.S. Army personnel, veterans, and their families with educational resources, professional development, and detailed coverage of Army-related issues.",
        "C4ISR Journal": "A publication that reports on the technology and systems used in command, control, communications, computers, intelligence, surveillance, and reconnaissance (C4ISR) within the defense sector.",
        "Defense News": "A publication focused on global defense and military sector news, offering coverage of policy, business, and technology in the defense industry.",
        "Breaking Defense": "An online defense magazine that provides timely analysis and news updates on defense programs, policy, business, and technology.",
        "RealClearDefense": "A military news site that provides analysis and commentary on defense policy, military affairs, and national security from various perspectives.",
        "United States Army": "The land warfare service branch of the United States Armed Forces, responsible for land-based military operations.",
        "Navy": "Refers to naval forces, specifically the United States Navy, which is a branch of the United States Armed Forces tasked with naval and amphibious warfare.",
        "Space News": "A publication that covers the business and politics of the space industry, providing news and insights on satellite technology, commercial space ventures, and space exploration.",
        "Navy Times": "An independent news source for sailors and members of the United States Navy, offering news, information, and analysis on naval life and policies.",
        "19FortyFive": "A military and foreign affairs magazine offering expert analysis and commentary on defense, national security, and international relations.",
        "Military Times": "A media brand reporting on the U.S. Armed Forces, providing independent news and information to the military community on various aspects of military life.",
        "Defensescoop": "A news site that reports on government IT and cybersecurity within the defense sector, focusing on how technology impacts national security.",
        "Defense One": "Provides in-depth coverage and analysis on U.S. defense and national security, focusing on future threats, the business of defense, and the politics of security.",
        "Axios": "A news website that provides analysis and insights on politics, business, technology, and media trends with a focus on brevity and straightforward reporting.",
        "News Break": "A local and breaking news application that aggregates and delivers news tailored to the specific interests and geographical location of the user.",
        "U.S. News": "Known for its rankings and annual reports on education, health care, and more, it also provides news analysis and reporting on politics, policy, and national and international issues.",
        "PBS NewsHour": "A nightly news broadcast on PBS that provides in-depth analysis of current events, interviews, and investigations, recognized for its detailed and balanced reporting.",
        "People's Daily": "The official newspaper of the Central Committee of the Communist Party of China, providing news and commentary on political, economic, and social issues relevant to China. Known for its role as the government's mouthpiece, it influences public opinion by presenting the Party's perspective.",
        "CCTV": "China Central Television is the predominant state television broadcaster in mainland China. CCTV has a network of 50 channels broadcasting different genres of programs, including news, culture, sports, and entertainment. Known for its emphasis on propagating the policies and achievements of the Communist Party of China.",
        "Caixin Global": "An independent media outlet in China known for its in-depth investigative reporting on finance, business, and economics. Caixin Global provides Chinese and global readers with a comprehensive range of financial and business news, aiming to offer insights into China's economic policy and market developments.",
        "Sina News": "Part of the Sina Corporation, this is a major Chinese news platform offering a wide array of content including breaking news, sports, entertainment, and finance. Sina News is known for its rapid dissemination of news and its ability to engage a large audience across various digital platforms.",
        "China News Service": "The second largest state-owned news agency in China, operating under the State Council of the People's Republic of China. Primarily targeting overseas audiences, China News Service publishes news in multiple languages, focusing on promoting China's policies and culture.",
        "Guangming Daily": "A state-owned newspaper in China, directly controlled by the Propaganda Department of the Communist Party of China. It primarily focuses on intellectual and cultural news and is known for its coverage of topics such as science, education, and literature, catering to an educated readership.",
        "The Pioneer": "One of India's oldest English-language newspapers, The Pioneer is known for its strong editorial content and comprehensive coverage of national and international news, politics, and culture.",
        "Oneindia News": "A popular digital news platform in India, Oneindia News provides real-time updates and in-depth coverage on a wide range of topics including politics, sports, entertainment, and technology, catering to both English and regional language audiences.",
        "ThePrint": "An independent digital news platform, ThePrint is known for its investigative journalism and detailed analysis on politics, policy, governance, and international affairs, offering insightful commentary and reporting.",
        "The Hindu": "One of India's most respected English-language daily newspapers, The Hindu is renowned for its balanced reporting, in-depth analysis, and comprehensive coverage of national and international news, politics, business, and culture.",
        "India Today": "A leading English-language news magazine and television channel, India Today is known for its in-depth reporting, investigative journalism, and extensive coverage of current affairs, politics, business, and lifestyle.",
        "The Economic Times": "India's premier business and financial newspaper, The Economic Times provides extensive coverage of economic news, financial markets, corporate developments, and policy analysis, catering to business professionals and investors.",
        "Deccan Herald": "A prominent English-language daily newspaper based in Bangalore, Deccan Herald offers comprehensive coverage of regional, national, and international news, with a focus on politics, business, culture, and sports.",
        "Zee News Hindi": "A leading Hindi-language news channel in India, Zee News Hindi delivers up-to-date news and analysis on politics, social issues, entertainment, and sports, catering to a wide Hindi-speaking audience.",
        "News18": "A major Indian news network, News18 provides comprehensive coverage of national and international news, politics, business, sports, and entertainment across multiple languages and platforms, including television and digital media.",
        "New Indian Express": "An English-language daily newspaper, The New Indian Express is known for its extensive coverage of national and regional news, politics, business, and culture, with a focus on in-depth reporting and analysis.",
        "ABP Live": "A popular Hindi-language news platform, ABP Live offers real-time news updates, analysis, and coverage of national and international events, politics, sports, and entertainment, reaching a broad Hindi-speaking audience.",
        "The Asian Age": "An English-language daily newspaper, The Asian Age provides comprehensive coverage of international, national, and regional news, with a focus on politics, business, and cultural affairs, appealing to a diverse readership.",
        "Outlook India": "A prominent Indian weekly news magazine and digital platform, Outlook India is known for its investigative journalism, in-depth analysis, and comprehensive coverage of politics, business, arts, and culture.",
        "DNA India": "An English-language daily newspaper in India, DNA India provides extensive coverage of national and international news, politics, business, sports, and entertainment, with a focus on breaking news and analysis.",
        "Telegraph India": "A leading English-language daily newspaper based in Kolkata, Telegraph India is known for its detailed reporting and insightful analysis on national and international news, politics, business, and culture.",
        "Mid-Day": "A popular Mumbai-based daily newspaper, Mid-Day focuses on local news, entertainment, lifestyle, and sports, offering engaging content and updates for the urban audience.",
        "Tribune India": "One of the oldest and most respected English-language daily newspapers in India, Tribune India offers comprehensive coverage of national and regional news, politics, business, and culture, with a strong focus on the northern states.",
        "Proceso": "A highly respected Mexican weekly news magazine known for its investigative journalism and in-depth coverage of political, social, and economic issues, with a focus on holding power to account.",
        "Animal Politico": "An independent digital news platform in Mexico, Animal Politico is known for its investigative journalism, detailed analysis, and comprehensive coverage of politics, human rights, and social issues.",
        "Aristegui Noticias": "A prominent Mexican news website led by journalist Carmen Aristegui, known for its investigative reporting, in-depth analysis, and coverage of national and international news, politics, and social issues.",
        "Heraldo de México": "A leading Mexican daily newspaper and digital platform, Heraldo de México offers comprehensive coverage of national and international news, politics, business, culture, and sports, with a focus on timely reporting and analysis.",
        "El Universal": "One of Mexico's major daily newspapers, known for its comprehensive coverage of national and international news, politics, culture, and entertainment, with a history of editorial independence and investigative journalism.",
        "Latest India News (india.com)": "A comprehensive digital news platform providing the latest updates on national and international news, politics, entertainment, sports, and lifestyle, catering to a broad Indian audience.",
        "The Quint": "An independent digital media platform in India, The Quint is known for its vibrant storytelling, investigative journalism, and in-depth coverage of news, politics, entertainment, and social issues.",
        "Business Today": "A leading Indian business magazine and digital platform, Business Today offers in-depth analysis, news, and insights on business, finance, economy, and industry trends, catering to professionals and business enthusiasts.",
        "The Wire": "An independent online news publication in India, The Wire is recognized for its investigative journalism, critical analysis, and detailed reporting on politics, social issues, and culture, often providing a platform for diverse voices.",
        "Diario de Chiapas": "A regional news outlet in Chiapas, Mexico, Diario de Chiapas provides comprehensive coverage of local news, politics, community events, and cultural issues, focusing on the interests and concerns of the Chiapas region.",
        "El Economista": "A leading Mexican business newspaper and digital platform, El Economista offers in-depth coverage of economic news, financial markets, business developments, and policy analysis, catering to professionals and investors.",
        "Mi Morelia": "A regional news website based in Morelia, Mexico, Mi Morelia provides extensive coverage of local news, events, politics, and culture, serving the interests of the Morelia community and surrounding areas.",
        "Uno TV": "A popular digital news platform in Mexico, Uno TV delivers real-time news updates and comprehensive coverage of national and international events, politics, entertainment, sports, and technology, catering to a broad Mexican audience.",
        "Salt Lake Tribune": "An independent daily newspaper based in Salt Lake City, Utah, the Salt Lake Tribune provides comprehensive coverage of local, national, and international news, with a focus on investigative journalism, politics, business, sports, and cultural issues. Known for its commitment to editorial independence and community engagement.",
        "Standard-Examiner": "A daily newspaper serving Northern Utah, particularly the Ogden area, the Standard-Examiner offers extensive coverage of local news, politics, business, sports, and community events, with a focus on timely reporting and in-depth analysis.",
        "Deseret News": "A major Utah newspaper with deep historical roots, Deseret News provides comprehensive coverage of local, national, and international news, focusing on politics, business, sports, and culture, along with a strong emphasis on family and community values.",
        "The Spectrum": "A regional newspaper serving Southern Utah, particularly the St. George area, The Spectrum offers extensive coverage of local news, community events, politics, business, and culture, catering to the interests and concerns of the Southern Utah community.",
        "Daily Herald Extra": "A daily newspaper serving Utah County, the Daily Herald Extra provides comprehensive coverage of local news, politics, business, sports, and community events, with a focus on timely reporting and analysis relevant to the Utah County community.",
        "Moab Times-Independent": "A weekly newspaper serving the Moab area, the Moab Times-Independent offers detailed coverage of local news, community events, politics, business, and cultural issues, focusing on the unique concerns and interests of the Moab community.",
        "Moab Sun News": "A local news outlet based in Moab, Utah, the Moab Sun News provides comprehensive coverage of community events, local news, politics, and cultural issues, serving the interests of the Moab community and surrounding areas.",
        "KUER": "A public radio station affiliated with the University of Utah, KUER offers in-depth coverage of local, national, and international news, with a focus on politics, business, culture, and community issues. Known for its high-quality journalism and public service.",
        "fox13now": "A local television news station in Salt Lake City, Utah, FOX13 delivers real-time news updates and comprehensive coverage of local, national, and international events, politics, entertainment, sports, and weather, catering to a broad Utah audience.",
        "KSL": "A major news outlet in Utah, KSL provides extensive coverage of local, national, and international news, politics, business, sports, and weather, through its television, radio, and digital platforms, known for its timely reporting and community focus."
    };

    const senateCandidateDict = {
        "Tim Kaine": "Tim Kaine",
        "Timothy Kaine": "Timothy Kaine",
        "Hung Cao": "Hung Cao",
        "Tim Kaine": "Tim Kaine",
        "Timothy Kaine": "Tim Kaine",
        "Sen. Kaine": "Tim Kaine",
        "Hung Cao": "Hung Cao",
        "Chris Murphy": "Chris Murphy",
        "Christopher Murphy": "Chris Murphy",
        "Christopher S. Murphy": "Chris Murphy",
        "Sen. Murphy": "Chris Murphy",
        "Robert Hyde": "Robert Hyde",
        "Robert Finley Hyde": "Robert Hyde",
        "Matthew Corey": "Matt Corey",
        "Matt Corey": "Matt Corey",
        "Rick Scott": "Rick Scott",
        "Debbie Mucarsel-Powell": "Debbie Mucarsel-Powell",
        "Feena Bonoan": "Feena Bonoan",
        "Elissa Slotkin": "Elissa Slotkin",
        "Mike Rogers": "Mike Rogers",
        "Douglas Marsh": "Douglas Marsh",
        "Jon Tester": "Jon Tester",
        "Sen. Tester": "Jon Tester",
        "Tim Sheehy": "Tim Sheehy",
        "Timothy Sheehy": "Tim Sheehy",
        "Michael Downey": "Michael Downey",
        "Sid Daoud": "Sid Daoud",
        "Sherrod Brown": "Sherrod Brown",
        "Bernie Moreno": "Bernie Moreno",
        "Don Kissick": "Don Kissick"
    }

    const houseCandidateDict = {
        "Barry Moore": "Barry Moore",
        "Tom Holmes": "Tom Holmes",
        "Caroleene Dobson": "Caroleene Dobson",
        "Shomari Figures": "Shomari Figures",
        "Mike Rogers": "Mike Rogers",
        "Robert Aderholt": "Robert Aderholt",
        "Dale Strong": "Dale Strong",
        "Gary Palmer": "Gary Palmer",
        "Elizabeth Anderson": "Elizabeth Anderson",
        "Terri Sewell": "Terri Sewell",
        "Robin Litaker": "Robin Litaker",
        "David Schweikert": "David Schweikert",
        "Amish Shah": "Amish Shah",
        "Eli Crane": "Eli Crane",
        "Jonathan Nez": "Jonathan Nez",
        "Jeffrey Zink": "Jeffrey Zink",
        "Alan Aversa": "Alan Aversa",
        "Greg Stanton": "Greg Stanton",
        "Kelly Cooper": "Kelly Cooper",
        "Andy Biggs": "Andy Biggs",
        "Katrina Schaffner": "Katrina Schaffner",
        "Juan Ciscomani": "Juan Ciscomani",
        "Kirsten Engel": "Kirsten Engel",
        "Raúl Grijalva": "Raúl Grijalva",
        "Daniel Butierez": "Daniel Butierez",
        "Abraham Hamadeh": "Abraham Hamadeh",
        "Gregory Whitten": "Gregory Whitten",
        "Paul Gosar": "Paul Gosar",
        "Quacy Smith": "Quacy Smith",
        "Rick Crawford": "Rick Crawford",
        "Rodney Govens": "Rodney Govens",
        "Steven Gene Parsons": "Steven Gene Parsons",
        "French Hill": "French Hill",
        "Marcus Jones": "Marcus Jones",
        "Steve Womack": "Steve Womack",
        "Caitlin Draper Mattelin": "Caitlin Draper Mattelin",
        "Bobby Wilson": "Bobby Wilson",
        "Bruce Westerman": "Bruce Westerman",
        "Risie Howard": "Risie Howard",
        "John White": "John White",
        "Doug LaMalfa": "Doug LaMalfa",
        "Rose Penelope Yee": "Rose Penelope Yee",
        "Jared Huffman": "Jared Huffman",
        "Chris Coulombe": "Chris Coulombe",
        "Kevin Kiley": "Kevin Kiley",
        "Jessica Morse": "Jessica Morse",
        "Mike Thompson": "Mike Thompson",
        "John Munn": "John Munn",
        "Tom McClintock": "Tom McClintock",
        "Mike Barkley": "Mike Barkley",
        "Ami Bera": "Ami Bera",
        "Christine Bish": "Christine Bish",
        "Doris Matsui": "Doris Matsui",
        "Tom Silva": "Tom Silva",
        "John Garamendi": "John Garamendi",
        "Rudy Recile": "Rudy Recile",
        "Josh Harder": "Josh Harder",
        "Kevin Lincoln II": "Kevin Lincoln II",
        "Mark DeSaulnier": "Mark DeSaulnier",
        "Katherine Piccinini": "Katherine Piccinini",
        "Nancy Pelosi": "Nancy Pelosi",
        "Bruce Lou": "Bruce Lou",
        "Lateefah Simon": "Lateefah Simon",
        "Jennifer Tran": "Jennifer Tran",
        "John Duarte": "John Duarte",
        "Adam Gray": "Adam Gray",
        "Eric Swalwell": "Eric Swalwell",
        "Vin Kruttiventi": "Vin Kruttiventi",
        "Kevin Mullin": "Kevin Mullin",
        "Anna Cheng Kramer": "Anna Cheng Kramer",
        "Sam Liccardo": "Sam Liccardo",
        "Evan Low": "Evan Low",
        "Ro Khanna": "Ro Khanna",
        "Anita Chen": "Anita Chen",
        "Zoe Lofgren": "Zoe Lofgren",
        "Peter Hernandez": "Peter Hernandez",
        "Jimmy Panetta": "Jimmy Panetta",
        "Jason Anderson": "Jason Anderson",
        "Vince Fong": "Vince Fong",
        "Jim Costa": "Jim Costa",
        "Michael Maher": "Michael Maher",
        "David Valadao": "David Valadao",
        "Rudy Salas": "Rudy Salas",
        "Jay Obernolte": "Jay Obernolte",
        "Derek Marshall": "Derek Marshall",
        "Salud Carbajal": "Salud Carbajal",
        "Thomas Cole": "Thomas Cole",
        "Raul Ruiz": "Raul Ruiz",
        "Ian Weeks": "Ian Weeks",
        "Julia Brownley": "Julia Brownley",
        "Michael Koslow": "Michael Koslow",
        "Mike Garcia": "Mike Garcia",
        "George Whitesides": "George Whitesides",
        "Judy Chu": "Judy Chu",
        "April Verlato": "April Verlato",
        "Benito Bernal": "Benito Bernal",
        "Luz Maria Rivas": "Luz Maria Rivas",
        "Alex Balekian": "Alex Balekian",
        "Laura Friedman": "Laura Friedman",
        "Daniel Martinez": "Daniel Martinez",
        "Gil Cisneros": "Gil Cisneros",
        "Brad Sherman": "Brad Sherman",
        "Larry Thompson": "Larry Thompson",
        "Pete Aguilar": "Pete Aguilar",
        "Tom Herman": "Tom Herman",
        "Jimmy Gomez": "Jimmy Gomez",
        "David Kim": "David Kim",
        "Norma Torres": "Norma Torres",
        "Mike Cargile": "Mike Cargile",
        "Ted Lieu": "Ted Lieu",
        "Melissa Toomim": "Melissa Toomim",
        "Sydney Kamlager-Dove": "Sydney Kamlager-Dove",
        "Juan Rey": "Juan Rey",
        "Linda Sánchez": "Linda Sánchez",
        "Eric Ching": "Eric Ching",
        "Mark Takano": "Mark Takano",
        "David Serpa": "David Serpa",
        "Young Kim": "Young Kim",
        "Joe Kerr": "Joe Kerr",
        "Ken Calvert": "Ken Calvert",
        "Will Rollins": "Will Rollins",
        "Robert Garcia": "Robert Garcia",
        "John Briscoe": "John Briscoe",
        "Maxine Waters": "Maxine Waters",
        "Steve Williams": "Steve Williams",
        "Nanette Barragán": "Nanette Barragán",
        "Roger Groh": "Roger Groh",
        "Michelle Steel": "Michelle Steel",
        "Derek Tran": "Derek Tran",
        "Lou Correa": "Lou Correa",
        "David Pan": "David Pan",
        "Scott Baugh": "Scott Baugh",
        "Dave Min": "Dave Min",
        "Darrell Issa": "Darrell Issa",
        "Stephen Houlahan": "Stephen Houlahan",
        "Mike Levin": "Mike Levin",
        "Matt Gunderson": "Matt Gunderson",
        "Scott Peters": "Scott Peters",
        "Peter Bono": "Peter Bono",
        "Sara Jacobs": "Sara Jacobs",
        "Bill Wells": "Bill Wells",
        "Juan Vargas": "Juan Vargas",
        "Justin Lee": "Justin Lee",
        "Diana DeGette": "Diana DeGette",
        "Valdamar Archuleta": "Valdamar Archuleta",
        "Joe Neguse": "Joe Neguse",
        "Marshall Dawson": "Marshall Dawson",
        "Jeff Hurd": "Jeff Hurd",
        "Adam Frisch": "Adam Frisch",
        "Lauren Boebert": "Lauren Boebert",
        "Trisha Calvarese": "Trisha Calvarese",
        "Jeff Crank": "Jeff Crank",
        "River Gassen": "River Gassen",
        "Michael Vance": "Michael Vance",
        "Jason Crow": "Jason Crow",
        "John Fabbricatore": "John Fabbricatore",
        "John Kittleson": "John Kittleson",
        "Brittany Pettersen": "Brittany Pettersen",
        "Sergei Matveyuk": "Sergei Matveyuk",
        "Patrick Bohan": "Patrick Bohan",
        "Yadira Caraveo": "Yadira Caraveo",
        "Gabe Evans": "Gabe Evans",
        "Eric Joss": "Eric Joss",
        "John Larson": "John Larson",
        "Jim Griffin": "Jim Griffin",
        "Joe Courtney": "Joe Courtney",
        "Mike France": "Mike France",
        "Rosa DeLauro": "Rosa DeLauro",
        "Rosa L. DeLauro": "Rosa DeLauro",
        "Rosa Luisa DeLauro": "Rosa DeLauro",
        "Michael Massey": "Michael Massey",
        "Jim Himes": "Jim Himes",
        "James Himes": "Jim Himes",
        "Jim A. Himes": "Jim Himes",
        "Michael Goldstein": "Michael Goldstein",
        "Jahana Hayes": "Jahana Hayes",
        "George Logan": "George Logan",
        "George S. Logan": "George Logan",
        "Eleanor Holmes Norton": "Eleanor Holmes Norton",
        "Myrtle Alexander": "Myrtle Alexander",
        "Kymone Freeman": "Kymone Freeman",
        "Gay Valimont": "Gay Valimont",
        "Meghann Hovey": "Meghann Hovey",
        "Tom Wells": "Tom Wells",
        "Aaron Bean": "Aaron Bean",
        "LaShonda Holloway": "LaShonda Holloway",
        "Todd Schaefer": "Todd Schaefer",
        "Jay McGovern": "Jay McGovern",
        "Gary Koniz": "Gary Koniz",
        "James Stockton": "James Stockton",
        "Richard Dembinsky": "Richard Dembinsky",
        "Darren Soto": "Darren Soto",
        "Marcus Carter": "Marcus Carter",
        "Barbie Harden Hall": "Barbie Harden Hall",
        "Rock Aboujaoude Jr.": "Rock Aboujaoude Jr.",
        "Anna Paulina Luna": "Anna Paulina Luna",
        "Tony D'Arrigo": "Tony D'Arrigo",
        "Kathy Castor": "Kathy Castor",
        "Nathaniel Snyder": "Nathaniel Snyder",
        "Christopher Bradley": "Christopher Bradley",
        "Pat Kemp": "Pat Kemp",
        "Greg Steube": "Greg Steube",
        "Ralph E. Hartman": "Ralph E. Hartman",
        "Scott Franklin": "Scott Franklin",
        "Byron Donalds": "Byron Donalds",
        "Kari Lerner": "Kari Lerner",
        "Thomas Witkop": "Thomas Witkop",
        "Elizabeth Felton": "Elizabeth Felton",
        "Lois Frankel": "Lois Frankel",
        "Jared Evan Moskowitz": "Jared Evan Moskowitz",
        "Frederica Wilson": "Frederica Wilson",
        "Lavern Spicer": "Lavern Spicer",
        "Ed Goldfarb": "Ed Goldfarb",
        "Joey David Atkins": "Joey David Atkins",
        "Carlos Gimenez": "Carlos Gimenez",
        "Phil Ehr": "Phil Ehr",
        "Earl Carter": "Earl Carter",
        "Patti Hewitt": "Patti Hewitt",
        "Sanford Bishop": "Sanford Bishop",
        "Wayne Johnson": "Wayne Johnson",
        "Brian Jack": "Brian Jack",
        "Maura Keller": "Maura Keller",
        "Hank Johnson": "Hank Johnson",
        "Eugene Yu": "Eugene Yu",
        "Nikema Williams": "Nikema Williams",
        "John Salvesen": "John Salvesen",
        "Lucy McBath": "Lucy McBath",
        "Jeff Criswell": "Jeff Criswell",
        "Rich McCormick": "Rich McCormick",
        "Bob Christian": "Bob Christian",
        "Austin Scott": "Austin Scott",
        "Darrius Butler": "Darrius Butler",
        "Andrew Clyde": "Andrew Clyde",
        "Tambrei Cash": "Tambrei Cash",
        "Mike Collins": "Mike Collins",
        "Lexy Doherty": "Lexy Doherty",
        "Barry Loudermilk": "Barry Loudermilk",
        "Katy Stamper": "Katy Stamper",
        "Rick Allen": "Rick Allen",
        "Liz Johnson": "Liz Johnson",
        "David Scott": "David Scott",
        "Jonathan Chavez": "Jonathan Chavez",
        "Marjorie Taylor Greene": "Marjorie Taylor Greene",
        "Marjorie Greene": "Marjorie Taylor Greene",
        "Shawn Harris": "Shawn Harris",
        "James Moylan": "James Moylan",
        "Ginger Cruz": "Ginger Cruz",
        "Russ Fulcher": "Russ Fulcher",
        "Brendan Gomez": "Brendan Gomez",
        "Kaylee Peterson": "Kaylee Peterson",
        "Matt Loesby": "Matt Loesby",
        "Michael K. Simpson": "Michael K. Simpson",
        "Idaho Law": "Idaho Law",
        "David Roth": "David Roth",
        "Todd Corsetti": "Todd Corsetti",
        "Jonathan Jackson": "Jonathan Jackson",
        "Marcus Lewis": "Marcus Lewis",
        "Robin Kelly": "Robin Kelly",
        "Ashley Ramos": "Ashley Ramos",
        "Delia Ramirez": "Delia Ramirez",
        "John Booras": "John Booras",
        "Jesus Garcia": "Jesus Garcia",
        "Mike Quigley": "Mike Quigley",
        "Tom Hanson": "Tom Hanson",
        "Sean Casten": "Sean Casten",
        "Niki Conforti": "Niki Conforti",
        "Danny K. Davis": "Danny K. Davis",
        "Chad Koppie": "Chad Koppie",
        "Raja Krishnamoorthi": "Raja Krishnamoorthi",
        "Mark Rice": "Mark Rice",
        "Jan Schakowsky": "Jan Schakowsky",
        "Brad Schneider": "Brad Schneider",
        "Jim Carris": "Jim Carris",
        "Bill Foster": "Bill Foster",
        "Jerry Evans": "Jerry Evans",
        "Mike Bost": "Mike Bost",
        "Brian Roberts": "Brian Roberts",
        "Nikki Budzinski": "Nikki Budzinski",
        "Joshua Loyd": "Joshua Loyd",
        "Lauren Underwood": "Lauren Underwood",
        "James Marter": "James Marter",
        "Mary Miller": "Mary Miller",
        "Darin LaHood": "Darin LaHood",
        "Eric Sorensen": "Eric Sorensen",
        "Joseph G. McGraw": "Joseph G. McGraw",
        "Frank Mrvan": "Frank Mrvan",
        "Randell Niemeyer": "Randell Niemeyer",
        "Rudy Yakym": "Rudy Yakym",
        "Lori A. Camp": "Lori A. Camp",
        "William Henry": "William Henry",
        "Marlin Stutzman": "Marlin Stutzman",
        "Kiley Adolph": "Kiley Adolph",
        "Jarrad Lancaster": "Jarrad Lancaster",
        "Jim Baird": "Jim Baird",
        "Derrick Holder": "Derrick Holder",
        "Victoria Spartz": "Victoria Spartz",
        "Deborah A. Pickett": "Deborah A. Pickett",
        "Jefferson Shreve": "Jefferson Shreve",
        "Cynthia Wirth": "Cynthia Wirth",
        "James Sceniak": "James Sceniak",
        "André Carson": "André Carson",
        "John Schmitz": "John Schmitz",
        "Mark Messmer": "Mark Messmer",
        "Erik Hurt": "Erik Hurt",
        "Erin Houchin": "Erin Houchin",
        "Timothy Peck": "Timothy Peck",
        "Mariannette Miller-Meeks": "Mariannette Miller-Meeks",
        "Christina Bohannan": "Christina Bohannan",
        "Ashley Hinson": "Ashley Hinson",
        "Sarah Corkery": "Sarah Corkery",
        "Zach Nunn": "Zach Nunn",
        "Lanon Baccam": "Lanon Baccam",
        "Randy Feenstra": "Randy Feenstra",
        "Ryan Melton": "Ryan Melton",
        "Tracey Mann": "Tracey Mann",
        "Derek Schmidt": "Derek Schmidt",
        "John Hauer": "John Hauer",
        "Steve Roberts": "Steve Roberts",
        "James Comer": "James Comer",
        "Erin Marshall": "Erin Marshall",
        "Brett Guthrie": "Brett Guthrie",
        "Hank Linderman": "Hank Linderman",
        "Morgan McGarvey": "Morgan McGarvey",
        "Mike Craven": "Mike Craven",
        "Thomas Massie": "Thomas Massie",
        "Hal Rogers": "Hal Rogers",
        "Andy Barr": "Andy Barr",
        "Randy Cravens": "Randy Cravens",
        "Andrew Harris": "Andrew Harris",
        "Blane Miller III": "Blane Miller III",
        "Joshua O'Brien": "Joshua O'Brien",
        "Kim Klacik": "Kim Klacik",
        "John A. Olszewski, Jr.": "John A. Olszewski, Jr.",
        "Jasen Wunder": "Jasen Wunder",
        "Rob Steinberger": "Rob Steinberger",
        "Sarah Elfreth": "Sarah Elfreth",
        "Miguel Barajas": "Miguel Barajas",
        "Glenn Ivey": "Glenn Ivey",
        "George McDermott": "George McDermott",
        "Steny Hoyer": "Steny Hoyer",
        "Michelle Talkington": "Michelle Talkington",
        "Neil Parrott": "Neil Parrott",
        "April McClain-Delaney": "April McClain-Delaney",
        "Kweisi Mfume": "Kweisi Mfume",
        "Scott Collier": "Scott Collier",
        "Jamie Raskin": "Jamie Raskin",
        "Cheryl Riley": "Cheryl Riley",
        "Jack Bergman": "Jack Bergman",
        "Hillary Scholten": "Hillary Scholten",
        "James Bronke": "James Bronke",
        "Haley Stevens": "Haley Stevens",
        "Nick Somberg": "Nick Somberg",
        "Douglas Campbell": "Douglas Campbell",
        "Thomas Bowman": "Thomas Bowman",
        "Tad Jude": "Tad Jude",
        "Kelly Morrison": "Kelly Morrison",
        "Trent Kelly": "Trent Kelly",
        "Dianne Black": "Dianne Black",
        "Bennie Thompson": "Bennie Thompson",
        "Ronald Eller": "Ronald Eller",
        "Michael Guest": "Michael Guest",
        "Mike Ezell": "Mike Ezell",
        "Craig Raybon": "Craig Raybon",
        "Ann Wagner": "Ann Wagner",
        "Ray Hartmann": "Ray Hartmann",
        "Bethany Mann": "Bethany Mann",
        "Sam Graves": "Sam Graves",
        "Pam May": "Pam May",
        "Eric Burlison": "Eric Burlison",
        "Jason Smith": "Jason Smith",
        "Ryan Zinke": "Ryan Zinke",
        "Monica Tranel": "Monica Tranel",
        "Dennis Hayes": "Dennis Hayes",
        "Troy Downing": "Troy Downing",
        "John B. Driscoll": "John B. Driscoll",
        "Mike Flood": "Mike Flood",
        "Carol Blood": "Carol Blood",
        "Don Bacon": "Don Bacon",
        "Tony Vargas": "Tony Vargas",
        "Adrian Smith": "Adrian Smith",
        "Daniel Ebers": "Daniel Ebers",
        "Dina Titus": "Dina Titus",
        "Mark Robertson": "Mark Robertson",
        "Mark Amodei": "Mark Amodei",
        "Lynn Chapman": "Lynn Chapman",
        "Javi Tachiquin": "Javi Tachiquin",
        "Greg Kidd": "Greg Kidd",
        "Susie Lee": "Susie Lee",
        "Drew Johnson": "Drew Johnson",
        "Steven Horsford": "Steven Horsford",
        "John J. Lee": "John J. Lee",
        "Russell Best": "Russell Best",
        "Timothy Ferreira": "Timothy Ferreira",
        "Donald Norcross": "Donald Norcross",
        "Theodore Liddell": "Theodore Liddell",
        "Jeff Van Drew": "Jeff Van Drew",
        "Joe Salerno": "Joe Salerno",
        "Rajesh Mohan": "Rajesh Mohan",
        "Herbert C. Conaway Jr.": "Herbert C. Conaway Jr.",
        "Chris Smith": "Chris Smith",
        "Matthew Jenkins": "Matthew Jenkins",
        "Josh Gottheimer": "Josh Gottheimer",
        "Mary Jo Guinchard": "Mary Jo Guinchard",
        "Frank Pallone": "Frank Pallone",
        "Scott Fegler": "Scott Fegler",
        "Thomas Kean, Jr.": "Thomas Kean, Jr.",
        "Susan Altman": "Susan Altman",
        "Rob Menendez": "Rob Menendez",
        "Anthony Valdes": "Anthony Valdes",
        "Bill Pascrell": "Bill Pascrell",
        "Billy Prempeh": "Billy Prempeh",
        "Carmen Bucco": "Carmen Bucco",
        "Mikie Sherrill": "Mikie Sherrill",
        "Joseph Belnome": "Joseph Belnome",
        "Bonnie Watson Coleman": "Bonnie Watson Coleman",
        "Darius Mayfield": "Darius Mayfield",
        "Melanie Ann Stansbury": "Melanie Ann Stansbury",
        "Steve Jones": "Steve Jones",
        "Gabriel Vasquez": "Gabriel Vasquez",
        "Yvette Herrell": "Yvette Herrell",
        "Teresa Leger Fernandez": "Teresa Leger Fernandez",
        "Sharon E. Clahchischilliage": "Sharon E. Clahchischilliage",
        "Nick LaLota": "Nick LaLota",
        "John Avlon": "John Avlon",
        "Andrew Garbarino": "Andrew Garbarino",
        "Rob Lubin": "Rob Lubin",
        "Tom Suozzi": "Tom Suozzi",
        "Michael LiPetri Jr.": "Michael LiPetri Jr.",
        "Anthony D'Esposito": "Anthony D'Esposito",
        "Laura Gillen": "Laura Gillen",
        "Gregory Meeks": "Gregory Meeks",
        "Paul King": "Paul King",
        "Grace Meng": "Grace Meng",
        "Thomas Zmich": "Thomas Zmich",
        "Nydia Velazquez": "Nydia Velazquez",
        "William Kregler": "William Kregler",
        "Hakeem Jeffries": "Hakeem Jeffries",
        "John Delaney": "John Delaney",
        "Yvette Clarke": "Yvette Clarke",
        "Menachem Raitport": "Menachem Raitport",
        "Daniel Goldman": "Daniel Goldman",
        "Alexander Dodenhoff": "Alexander Dodenhoff",
        "Paul Briscoe": "Paul Briscoe",
        "Nicole Malliotakis": "Nicole Malliotakis",
        "Andrea Morse": "Andrea Morse",
        "Jerrold Nadler": "Jerrold Nadler",
        "Mike Zumbluskas": "Mike Zumbluskas",
        "Adriano Espaillat": "Adriano Espaillat",
        "Ruben Vargas": "Ruben Vargas",
        "Alexandria Ocasio-Cortez": "Alexandria Ocasio-Cortez",
        "AOC": "Alexandria Ocasio-Cortez",
        "Tina Forte": "Tina Forte",
        "Ritchie Torres": "Ritchie Torres",
        "Gonzalo Duran": "Gonzalo Duran",
        "Miriam Flisser": "Miriam Flisser",
        "George Latimer": "George Latimer",
        "Michael Lawler": "Michael Lawler",
        "Mondaire Jones": "Mondaire Jones",
        "Anthony Frascone": "Anthony Frascone",
        "Pat Ryan": "Pat Ryan",
        "Alison Esposito": "Alison Esposito",
        "Marcus Molinaro": "Marcus Molinaro",
        "Josh Riley": "Josh Riley",
        "Paul Tonko": "Paul Tonko",
        "Kevin Waltz": "Kevin Waltz",
        "Elise Stefanik": "Elise Stefanik",
        "Paula Collins": "Paula Collins",
        "Brandon Williams": "Brandon Williams",
        "John Mannion": "John Mannion",
        "Nick Langworthy": "Nick Langworthy",
        "Thomas Carle": "Thomas Carle",
        "Claudia Tenney": "Claudia Tenney",
        "David Wagenhauser": "David Wagenhauser",
        "Joseph Morelle": "Joseph Morelle",
        "Gregg Sadwick": "Gregg Sadwick",
        "Timothy M. Kennedy": "Timothy M. Kennedy",
        "Anthony Marecki": "Anthony Marecki",
        "Donald Davis": "Donald Davis",
        "Laurie Buckhout": "Laurie Buckhout",
        "Tom Bailey": "Tom Bailey",
        "Deborah Ross": "Deborah Ross",
        "Alan Swain": "Alan Swain",
        "Michael Dublin": "Michael Dublin",
        "Gregory Murphy": "Gregory Murphy",
        "Gheorghe Cormos": "Gheorghe Cormos",
        "Valerie Foushee": "Valerie Foushee",
        "Eric Blankenburg": "Eric Blankenburg",
        "Guy Meilleur": "Guy Meilleur",
        "Virginia Foxx": "Virginia Foxx",
        "Chuck Hubbard": "Chuck Hubbard",
        "Addison McDowell": "Addison McDowell",
        "David Rouzer": "David Rouzer",
        "Marlando Pridgen": "Marlando Pridgen",
        "Mark Harris": "Mark Harris",
        "Justin Dues": "Justin Dues",
        "Richard Hudson": "Richard Hudson",
        "Nigel Bristow": "Nigel Bristow",
        "Shelane Etchison": "Shelane Etchison",
        "Pat Harrigan": "Pat Harrigan",
        "Ralph Scott Jr.": "Ralph Scott Jr.",
        "Steven Feldman": "Steven Feldman",
        "Chuck Edwards": "Chuck Edwards",
        "Caleb Rudow": "Caleb Rudow",
        "Alma Adams": "Alma Adams",
        "Addul Ali": "Addul Ali",
        "Brad Knott": "Brad Knott",
        "Frank Pierce": "Frank Pierce",
        "Timothy K. Moore": "Timothy K. Moore",
        "Pamela Genant": "Pamela Genant",
        "Julie Fedorchak": "Julie Fedorchak",
        "Trygve Hammer": "Trygve Hammer",
        "Greg Landsman": "Greg Landsman",
        "Orlando Sonza": "Orlando Sonza",
        "David Taylor": "David Taylor",
        "Samantha Meadows": "Samantha Meadows",
        "Joyce Beatty": "Joyce Beatty",
        "Michael Young": "Michael Young",
        "Jim Jordan": "Jim Jordan",
        "Tamie Wilson": "Tamie Wilson",
        "Bob Latta": "Bob Latta",
        "Keith Mundy": "Keith Mundy",
        "Michael Rulli": "Michael Rulli",
        "Michael Kripchak": "Michael Kripchak",
        "Max Miller": "Max Miller",
        "Matthew Diemer": "Matthew Diemer",
        "Dennis Kucinich": "Dennis Kucinich",
        "Warren Davidson": "Warren Davidson",
        "Vanessa Enoch": "Vanessa Enoch",
        "Marcy Kaptur": "Marcy Kaptur",
        "Derek Merrin": "Derek Merrin",
        "Michael Turner": "Michael Turner",
        "Amy Cox": "Amy Cox",
        "Michael Harbaugh": "Michael Harbaugh",
        "Shontel Brown": "Shontel Brown",
        "Alan Rapoport": "Alan Rapoport",
        "Tracy DeForde": "Tracy DeForde",
        "Sean Freeman": "Sean Freeman",
        "Christopher Zelonish": "Christopher Zelonish",
        "Troy Balderson": "Troy Balderson",
        "Jerrad Christian": "Jerrad Christian",
        "Emilia Sykes": "Emilia Sykes",
        "Kevin Coughlin": "Kevin Coughlin",
        "David Joyce": "David Joyce",
        "Brian Kenderes": "Brian Kenderes",
        "Mike Carey": "Mike Carey",
        "Adam Miller": "Adam Miller",
        "Kevin Hern": "Kevin Hern",
        "Dennis Baker": "Dennis Baker",
        "Mark Sanders": "Mark Sanders",
        "Josh Brecheen": "Josh Brecheen",
        "Brandon Wade": "Brandon Wade",
        "Ronnie Hopkins": "Ronnie Hopkins",
        "Frank Lucas": "Frank Lucas",
        "Tom Cole": "Tom Cole",
        "Mary Brannon": "Mary Brannon",
        "James Stacy": "James Stacy",
        "Stephanie Bice": "Stephanie Bice",
        "Madison Horn": "Madison Horn",
        "Suzanne Bonamici": "Suzanne Bonamici",
        "Bob Todd": "Bob Todd",
        "Cliff Bentz": "Cliff Bentz",
        "Dan Ruby": "Dan Ruby",
        "Joanna Harbour": "Joanna Harbour",
        "Maxine Dexter": "Maxine Dexter",
        "Val Hoyle": "Val Hoyle",
        "Monique DeSpain": "Monique DeSpain",
        "Lori Chavez-DeRemer": "Lori Chavez-DeRemer",
        "Janelle Bynum": "Janelle Bynum",
        "Andrea Salinas": "Andrea Salinas",
        "Mike Erickson": "Mike Erickson",
        "Brian Fitzpatrick": "Brian Fitzpatrick",
        "Ashley Ehasz": "Ashley Ehasz",
        "Brendan Boyle": "Brendan Boyle",
        "Aaron Bashir": "Aaron Bashir",
        "Dwight Evans": "Dwight Evans",
        "Madeleine Dean": "Madeleine Dean",
        "David Winkler": "David Winkler",
        "Mary Gay Scanlon": "Mary Gay Scanlon",
        "Alfe Goodwin": "Alfe Goodwin",
        "Chrissy Houlahan": "Chrissy Houlahan",
        "Neil Young": "Neil Young",
        "Susan Wild": "Susan Wild",
        "Ryan Mackenzie": "Ryan Mackenzie",
        "Matt Cartwright": "Matt Cartwright",
        "Rob Bresnahan Jr.": "Rob Bresnahan Jr.",
        "Dan Meuser": "Dan Meuser",
        "Amanda Waldman": "Amanda Waldman",
        "Scott Perry": "Scott Perry",
        "Janelle Stelson": "Janelle Stelson",
        "Lloyd Smucker": "Lloyd Smucker",
        "Jim Atkinson": "Jim Atkinson",
        "Summer Lee": "Summer Lee",
        "James Hayes": "James Hayes",
        "John Joyce": "John Joyce",
        "Beth Farnham": "Beth Farnham",
        "Guy Reschenthaler": "Guy Reschenthaler",
        "Chris Dziados": "Chris Dziados",
        "Glenn Thompson": "Glenn Thompson",
        "Zach Womer": "Zach Womer",
        "Mike Kelly": "Mike Kelly",
        "Preston Nouri": "Preston Nouri",
        "Chris Deluzio": "Chris Deluzio",
        "Rob Mercuri": "Rob Mercuri",
        "Nancy Mace": "Nancy Mace",
        "Michael B. Moore": "Michael B. Moore",
        "Joe Wilson": "Joe Wilson",
        "David Robinson II": "David Robinson II",
        "Sheri Biggs": "Sheri Biggs",
        "Michael Bedenbaugh": "Michael Bedenbaugh",
        "Bryon Best": "Bryon Best",
        "William Timmons": "William Timmons",
        "Mark Hackett": "Mark Hackett",
        "Kathryn Harvey": "Kathryn Harvey",
        "Ralph Norman": "Ralph Norman",
        "Evangeline Hundley": "Evangeline Hundley",
        "James Clyburn": "Jim Clyburn",
        "Jim Clyburn": "Jim Clyburn",
        "Duke Buckner": "Duke Buckner",
        "Joseph Oddo": "Joseph Oddo",
        "Michael Simpson": "Michael Simpson",
        "Gregg Marcel Dixon": "Gregg Marcel Dixon",
        "Russell Fry": "Russell Fry",
        "Mal Hyman": "Mal Hyman",
        "Dusty Johnson": "Dusty Johnson",
        "Sheryl Johnson": "Sheryl Johnson",
        "Diana Harshbarger": "Diana Harshbarger",
        "Kevin Jenkins": "Kevin Jenkins",
        "Richard Baker": "Richard Baker",
        "Levi Brake": "Levi Brake",
        "Tim Burchett": "Tim Burchett",
        "Jane George": "Jane George",
        "Chuck Fleischmann": "Chuck Fleischmann",
        "Jack Allen": "Jack Allen",
        "Jean Howard-Hill": "Jean Howard-Hill",
        "Stephen King": "Stephen King",
        "Scott DesJarlais": "Scott DesJarlais",
        "Victoria Broderick": "Victoria Broderick",
        "Earnest Ensley": "Earnest Ensley",
        "Keith Nolan": "Keith Nolan",
        "Andy Ogles": "Andy Ogles",
        "Maryam Abolfazli": "Maryam Abolfazli",
        "Yomi Faparusi": "Yomi Faparusi",
        "Jim Larkin": "Jim Larkin",
        "Bob Titley": "Bob Titley",
        "John Rose": "John Rose",
        "Lore Bergman": "Lore Bergman",
        "Mark Green": "Mark Green",
        "Megan Barry": "Megan Barry",
        "Shaun Greene": "Shaun Greene",
        "David Kustoff": "David Kustoff",
        "Sarah Freeman": "Sarah Freeman",
        "James Hart": "James Hart",
        "Steve Cohen": "Steve Cohen",
        "Charlotte Bergmann": "Charlotte Bergmann",
        "Dennis Clark": "Dennis Clark",
        "Wendell Wells": "Wendell Wells",
        "Nathaniel Moran": "Nathaniel Moran",
        "Daniel Crenshaw": "Daniel Crenshaw",
        "Peter Filler": "Peter Filler",
        "Chuck Benton": "Chuck Benton",
        "Keith Self": "Keith Self",
        "Sandeep Srivastava": "Sandeep Srivastava",
        "Christopher Claytor": "Christopher Claytor",
        "Pat Fallon": "Pat Fallon",
        "Simon Cardell": "Simon Cardell",
        "Mark Boler": "Mark Boler",
        "Lance Gooden": "Lance Gooden",
        "Ruth Torres": "Ruth Torres",
        "Jake Ellzey": "Jake Ellzey",
        "John Love III": "John Love III",
        "Lizzie Pannill Fletcher": "Lizzie Pannill Fletcher",
        "Caroline Kane": "Caroline Kane",
        "Morgan Luttrell": "Morgan Luttrell",
        "Laura Jones": "Laura Jones",
        "Al Green": "Al Green",
        "Michael McCaul": "Michael McCaul",
        "Theresa Boisseau": "Theresa Boisseau",
        "Bill Kelsey": "Bill Kelsey",
        "August Pfluger": "August Pfluger",
        "Wacey Alpha Cody": "Wacey Alpha Cody",
        "Craig Goldman": "Craig Goldman",
        "Trey Hunt": "Trey Hunt",
        "Ronny Jackson": "Ronny Jackson",
        "Mike Kolls": "Mike Kolls",
        "Randy Weber": "Randy Weber",
        "Rhonda Hart": "Rhonda Hart",
        "Monica De La Cruz": "Monica De La Cruz",
        "Michelle Vallejo": "Michelle Vallejo",
        "Arthur DiBianca": "Arthur DiBianca",
        "Veronica Escobar": "Veronica Escobar",
        "Irene Armendariz-Jackson": "Irene Armendariz-Jackson",
        "Pete Sessions": "Pete Sessions",
        "Mark Lorenzen": "Mark Lorenzen",
        "Clyde Garland": "Clyde Garland",
        "Lana Centonze": "Lana Centonze",
        "Jodey Arrington": "Jodey Arrington",
        "Nathan Lewis": "Nathan Lewis",
        "Bernard Johnson": "Bernard Johnson",
        "Joaquin Castro": "Joaquin Castro",
        "Pat Dixon": "Pat Dixon",
        "Chip Roy": "Chip Roy",
        "Kristin Hook": "Kristin Hook",
        "Bob King": "Bob King",
        "Troy Nehls": "Troy Nehls",
        "Marquette Greene-Scott": "Marquette Greene-Scott",
        "Tony Gonzales": "Tony Gonzales",
        "Santos Limon": "Santos Limon",
        "Beth Van Duyne": "Beth Van Duyne",
        "Sam Eppler": "Sam Eppler",
        "Roger Williams": "Roger Williams",
        "Brandon Gill": "Brandon Gill",
        "Ernest Lineberger III": "Ernest Lineberger III",
        "Phil Gray": "Phil Gray",
        "Michael Cloud": "Michael Cloud",
        "Tanya Lloyd": "Tanya Lloyd",
        "Henry Cuellar": "Henry Cuellar",
        "Jay Furman": "Jay Furman",
        "Bailey Cole": "Bailey Cole",
        "Sylvia Garcia": "Sylvia Garcia",
        "Alan Garza": "Alan Garza",
        "Jasmine Crockett": "Jasmine Crockett",
        "Ken Ashby": "Ken Ashby",
        "John Carter": "John Carter",
        "Stuart Whitlow": "Stuart Whitlow",
        "Caleb Ferrell": "Caleb Ferrell",
        "Darrell Day": "Darrell Day",
        "Julie Johnson": "Julie Johnson",
        "Kevin Hale": "Kevin Hale",
        "Marc Veasey": "Marc Veasey",
        "Patrick Gillespie": "Patrick Gillespie",
        "Vicente Gonzalez": "Vicente Gonzalez",
        "Mayra Flores": "Mayra Flores",
        "Brent Lewis": "Brent Lewis",
        "Greg Casar": "Greg Casar",
        "Steven Wright": "Steven Wright",
        "Clark Patterson": "Clark Patterson",
        "Brian Babin": "Brian Babin",
        "Dayna Steele": "Dayna Steele",
        "Lloyd Doggett": "Lloyd Doggett",
        "Jenny Garcia Sharon": "Jenny Garcia Sharon",
        "Girish Altekar": "Girish Altekar",
        "Wesley Hunt": "Wesley Hunt",
        "Melissa McDonough": "Melissa McDonough",
        "Chad Abbey": "Chad Abbey",
        "Blake Moore": "Blake Moore",
        "Bill Campbell": "Bill Campbell",
        "Daniel Cottam": "Daniel Cottam",
        "Celeste Maloy": "Celeste Maloy",
        "Cassie Easley": "Cassie Easley",
        "Nathaniel Woodward": "Nathaniel Woodward",
        "Tyler Murset": "Tyler Murset",
        "Mike Kennedy": "Mike Kennedy",
        "Glenn J. Wright": "Glenn J. Wright",
        "Burgess Owens": "Burgess Owens",
        "Katrina Fallick-Wang": "Katrina Fallick-Wang",
        "M. Evan Bullard": "M. Evan Bullard",
        "Vaughn R. Cook": "Vaughn R. Cook",
        "Rob Wittman": "Rob Wittman",
        "Robert Wittman": "Rob Wittman",
        "Rob Wittmann": "Rob Wittman",
        "Robert J. Wittman": "Rob Wittman",
        "Leslie Mehta": "Leslie Mehta",
        "Jennifer Kiggans": "Jennifer Kiggans",
        "Jen Kiggans": "Jennifer Kiggans",
        "Missy Cotter Smasal": "Missy Cotter Smasal",
        "Missy Smasal": "Missy Smasal",
        "Bobby Scott": "Bobby Scott",
        "Robert Scott": "Bobby Scott",
        "Robert C. Scott": "Bobby Scott",
        "John Sitka": "John Sitka",
        "John Sitka III": "John Sitka",
        "Rhoda Young": "Rhoda Young",
        "Rhoda Taylor-Young": "Rhoda Young",
        "Jennifer McClellan": "Jennifer McClellan",
        "Jen McClellan": "Jennifer McClellan",
        "Bill Moher": "Bill Moher",
        "John McGuire": "John McGuire",
        "Gloria Tinsley Witt": "Gloria Witt",
        "Gloria Witt": "Gloria Witt",
        "Ben Cline": "Ben Cline",
        "Benjamin Cline": "Ben Cline",
        "Benjamin Lee Cline": "Ben Cline",
        "Ken Mitchell": "Ken Mitchell",
        "Kenneth Mitchell": "Ken Mitchell",
        "Derrick Anderson": "Derrick Anderson",
        "Eugene Vindman": "Eugene Vindman",
        "Don Beyer": "Don Beyer",
        "Donald Beyer": "Don Beyer",
        "Donald Sternoff Beyer": "Don Beyer",
        "Donald Sternoff Beyer Jr.": "Don Beyer",
        "Jerry Torres": "Jerry Torres",
        "Bentley Hensel": "Bentley Hensel",
        "Morgan Griffith": "Morgan Griffith",
        "Karen Baker": "Karen Baker",
        "Mike Clancy": "Mike Clancy",
        "Michael Clancy": "Mike Clancy",
        "Suhas Subramanyam": "Suhas Subramanyam",
        "Gerry Connolly": "Gerry Connolly",
        "Gerald Connolly": "Gerry Connolly",
        "Gerald Edward Connolly": "Gerry Connolly",
        "Mike Van Meter": "Mike Van Meter",
        "Michael Van Meter": "Mike Van Meter",
        "Carol Miller": "Carol Miller",
        "Chris Reed": "Chris Reed",
        "Riley Moore": "Riley Moore",
        "Steven Wendelin": "Steven Wendelin"
    }


    function getCandidateSentiment(candidateSentiment, name) {
        // Iterate through the candidate sentiment array
        for (let i = 0; i < candidateSentiment.length; i++) {
            if (candidateSentiment[i]["Name"] === name) {
                return candidateSentiment[i]["Sentiment"];
            }
        }
        return null;
    }

    const extractPersonSnippet = (text, targetPerson) => {
        // Combine dictionaries
        const personVariations = {
            ...senateCandidateDict,
            ...houseCandidateDict
        };

        // Find all variations for the target person
        const variations = Object.keys(personVariations).filter(variation => personVariations[variation] === targetPerson);

        // Normalize the text and variations
        const normalize = (str) => str.toLowerCase().replace(/[^\w\s]/gi, '');
        const normalizedVariations = variations.map(normalize);

        // Split the text into sentences
        const sentences = text.match(/<p>.*?<\/p>/g).map(sentence => sentence.replace(/<\/?p>/g, '').trim());

        if (!sentences) return '';

        let snippet = '';
        let targetIndex = -1;

        // Find the sentence containing any variation of the person's name
        for (let i = 0; i < sentences.length; i++) {
            const sentence = sentences[i];
            const normalizedSentence = normalize(sentence);

            if (normalizedVariations.some(variation => normalizedSentence.includes(variation))) {
                targetIndex = i;
                break;
            }
        }

        if (targetIndex !== -1) {
            if (targetIndex > 0) {
                snippet += sentences[targetIndex - 1] + " ";
            }
            snippet += `<span style="background-color: #CCCCCC;">${sentences[targetIndex]}</span> `;
            if (targetIndex < sentences.length - 1) {
                snippet += sentences[targetIndex + 1];
            }
        }

        return DOMPurify.sanitize(snippet);
    };


    // Temp placeholder -- figure this out later
    const orgDict = {}

    const extractOrgSnippet = (text, targetOrg) => {

        const orgVariations = orgDict;

        // Find all variations for the target org
        const variations = Object.keys(orgVariations).filter(variation => orgVariations[variation] === targetOrg);

        // Split the text into sentences
        const sentences = text.match(/<p>.*?<\/p>/g).map(sentence => sentence.replace(/<\/?p>/g, '').trim());

        if (!sentences) return '';

        let snippet = '';
        let targetIndex = -1;

        // Find the sentence containing an exact match for any variation of the org's name
        for (let i = 0; i < sentences.length; i++) {
            const sentence = sentences[i];

            if (variations.some(variation => sentence.includes(variation))) { // Case-sensitive and exact match
                targetIndex = i;
                break;
            }
        }

        if (targetIndex !== -1) {
            if (targetIndex > 0) {
                snippet += sentences[targetIndex - 1] + " ";
            }
            snippet += `<span style="background-color: #CCCCCC;">${sentences[targetIndex]}</span> `;
            if (targetIndex < sentences.length - 1) {
                snippet += sentences[targetIndex + 1];
            }
        }

        return DOMPurify.sanitize(snippet);
    };


    const extractOrgSnippetFromListOfOrgs = (text, targetOrgsDict) => {
        const orgVariations = targetOrgsDict;

        // Split the text into sentences
        const sentences = text.match(/<p>.*?<\/p>/g)?.map(sentence => sentence.replace(/<\/?p>/g, '').trim());

        if (!sentences) return '';

        let snippet = '';

        // Iterate through the org variations
        Object.keys(orgVariations).forEach(targetOrg => {
            const variations = Object.keys(orgVariations).filter(variation => orgVariations[variation] === targetOrg);

            let targetIndex = -1;

            // Find the sentence containing an exact match for any variation of the org's name
            for (let i = 0; i < sentences.length; i++) {
                const sentence = sentences[i];

                if (variations.some(variation => sentence.includes(variation))) { // Case-sensitive and exact match
                    targetIndex = i;
                    break;
                }
            }

            if (targetIndex !== -1) {
                if (targetIndex > 0) {
                    snippet += sentences[targetIndex - 1] + " ";
                }
                snippet += `<span style="background-color: #CCCCCC;">${sentences[targetIndex]}</span> `;
                if (targetIndex < sentences.length - 1) {
                    snippet += sentences[targetIndex + 1] + " ";
                }
            }
        });

        return DOMPurify.sanitize(snippet.trim());
    };


    const findOrgInArticle = (organizations, targetOrgsDict) => {
        const orgVariations = targetOrgsDict;

        // Iterate through the list of organizations
        for (let org of organizations) {
            const variations = Object.keys(orgVariations).filter(variation => orgVariations[variation] === org);

            // Check if any variation of the org's name exists (case-sensitive)
            if (variations.length > 0) {
                return org; // Return the first matched organization
            }
        }
        // Return null if no organization was found
        return null;
    };


    const [expandedRows, setExpandedRows] = useState([]);

    const [copiedIndex, setCopiedIndex] = useState(null);

    const handleCopy = (articleId, index) => {
        copyToClipboard(articleId);
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 1000); // Reset the copied state after 2 seconds
    };

    const toggleRow = (index) => {
        const isExpanded = expandedRows.includes(index);
        if (isExpanded) {
            setExpandedRows(expandedRows.filter(i => i !== index));
        } else {
            setExpandedRows([...expandedRows, index]);
        }
    };

    const [expandAll, setExpandAll] = useState(false);

    const toggleExpandAll = () => {
        if (expandAll) {
            setExpandedRows([]);
        } else {
            setExpandedRows(filteredData.map((_, index) => index));
        }
        setExpandAll(!expandAll);
    };


    const colorMapping = {
        "None": 'rgb(192, 192, 192)',       // Gray
        "Happiness": 'rgb(255, 182, 193)',  // Light pink
        "Sadness": 'rgb(17, 176, 179)',     // Teal
        "Disgust": 'rgb(255, 190, 28)',     // Yellow
        "Anger": 'rgb(242, 22, 22)',        // Bright red
        "Fear": 'rgb(122, 203, 57)',        // Green
        "Surprise": 'rgb(46, 70, 155)',     // Blue
    };

    const mapSentiment = (emotion) => {
        const textColor = "#222222"; // Default dark text color for readability
        switch (emotion) {
            case "Happiness":
                return { text: "Happiness", color: colorMapping["Happiness"], textColor };
            case "Sadness":
                return { text: "Sadness", color: colorMapping["Sadness"], textColor };
            case "Disgust":
                return { text: "Disgust", color: colorMapping["Disgust"], textColor };
            case "Anger":
                return { text: "Anger", color: colorMapping["Anger"], textColor: "#FFFFFF" }; // White text for readability
            case "Fear":
                return { text: "Fear", color: colorMapping["Fear"], textColor };
            case "Surprise":
                return { text: "Surprise", color: colorMapping["Surprise"], textColor: "#FFFFFF" }; // White text for readability
            case "None":
            default:
                return { text: "Neutral", color: colorMapping["None"], textColor };
        }
    };


    const mapCandidateSentiment = (sentiment) => {
        if (sentiment == "Negative" || sentiment == "Critical") return { text: "Critical", color: '#F7B4BB', textColor: "#222222" }; // Bright red
        if (sentiment == "Neither" || sentiment == "Neutral") return { text: "Neutral", color: '#CCCCCC', textColor: "#222222" }; // Gray
        if (sentiment == "Positive" || sentiment == "Supportive") return { text: "Supportive", color: '#44B200', textColor: "FFFFFF" }; // Bright green
    }

    const mapCharged = (charge) => {
        if (charge == "Yes") return { text: "Contains Charged Language", color: '#F7B4BB', textColor: "#222222" }; // Bright red
        if (charge == "No") return { text: "Neutral Language", color: '#CCCCCC', textColor: "#222222" }; // Gray
    }

    const highlightKeywords = (text, semanticSearch) => {
        if (!text) return text;

        let combinedKeywords = ["defaultKeyword"];

        if (semanticSearch) {
            combinedKeywords.push(semanticSearch); // Add the semantic search query to the keywords array
        }

        if (combinedKeywords.length === 0) return text;

        const pattern = combinedKeywords.map(keyword => `\\b${keyword}(\\'s)?\\b`).join(' | ');
        const regex = new RegExp(pattern, 'gi');

        return text.replace(regex, match => `<strong>${match}</strong>`);
    };

    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData(data);
        if (expandAll) {
            setExpandedRows(data.map((_, index) => index));
        }
    }, [data, expandAll]);


    // Show most recent dates first
    useEffect(() => {
        if (isInitialLoad) {
            const sortedData = [...data].sort((a, b) => {
                const dateA = new Date(a.date_time_published_utc);
                const dateB = new Date(b.date_time_published_utc);
                return dateB - dateA; // recent articles first for initial load
            });
            setFilteredData(sortedData);
        } else {
            setFilteredData(data); // original order if initialLoad is false
        }
    }, [data]); // Remove isInitialLoad from the dependency array

    // Check if isMobile
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Track if user is signed in
    const { user } = useUser();

    // Track user bookmarks
    const { userData, saveSpeech, removeSpeech } = useFirestore();

    const toggleFavorite = (row) => {
        const speechLink = row.link; // Assuming 'link' is the field you want to save

        if (
            userData &&
            userData.savedSpeeches.includes(speechLink)
        ) {
            removeSpeech(speechLink);
        }
        else {
            saveSpeech(speechLink);
        }
    };

    // Sorting states
    const [sortSourceDirection, setSortSourceDirection] = useState(null);
    const [sortCountryDirection, setSortCountryDirection] = useState(null);
    const [sortDateDirection, setSortDateDirection] = useState(null);

    const copyToClipboard = (link) => {
        const baseURL = window.location.origin; // Get the current site's base URL
        const formattedLink = `${baseURL}/article/${getClusterPath()}/${link}`;

        // Check if clipboard API is available
        if (!navigator.clipboard) {
            console.error('Clipboard not available.');
            return;
        }

        navigator.clipboard.writeText(formattedLink)
            .then(() => {
                console.log('Link copied to clipboard.'); // Or handle this silently
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const [dateSortOrder, setDateSortOrder] = useState('none');
    const [countrySortOrder, setCountrySortOrder] = useState('none');
    const [sourceSortOrder, setSourceSortOrder] = useState('none');

    const toggleSortOrder = (column) => {
        switch (column) {
            case 'date':
                setDateSortOrder(dateSortOrder === 'reverse-chronological' ? 'chronological' : 'reverse-chronological');
                // Reset other sort orders
                setCountrySortOrder('none');
                setSourceSortOrder('none');
                break;
            case 'country':
                setCountrySortOrder(countrySortOrder === 'alphabetical' ? 'reverse-alphabetical' : 'alphabetical');
                // Reset other sort orders
                setDateSortOrder('none');
                setSourceSortOrder('none');
                break;
            case 'source':
                setSourceSortOrder(sourceSortOrder === 'alphabetical' ? 'reverse-alphabetical' : 'alphabetical');
                // Reset other sort orders
                setDateSortOrder('none');
                setCountrySortOrder('none');
                break;
            default:
                break;
        }
    };


    const getSortedData = () => {
        return [...filteredData].sort((a, b) => {
            if (sourceSortOrder !== 'none') {
                return sourceSortOrder === 'alphabetical' ? a.source.localeCompare(b.source) : b.source.localeCompare(a.source);
            }

            if (countrySortOrder !== 'none') {
                return countrySortOrder === 'alphabetical' ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country);
            }

            if (dateSortOrder !== 'none') {
                const dateA = new Date(a.date_time_published_utc);
                const dateB = new Date(b.date_time_published_utc);
                return dateSortOrder === 'chronological' ? dateA - dateB : dateB - dateA;
            }

            return 0;
        });
    };

    const handleShare = async (row) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: row.title, // Title of the content to share
                    text: `Here's a summary of "${row.title}" from ${row.source}`, // Text to accompany the shared content
                    url: `https://verbaai.org/article/${row.article_id}`, // The URL to share
                });
                console.log('Article shared successfully');
            } catch (error) {
                console.error('Error sharing the article:', error);
            }
        } else {
            console.error('Web Share API is not supported in your browser.');
        }
    };

    const renderMobileTableBeta = () => (
        <div>
            <Table>
                <tbody>
                    {filteredData.map((row, index) => {
                        const isExpanded = expandedRows.includes(index);
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div>
                                            {source_info_dict[row.source] && source_info_dict[row.source].trim() !== '' ? (
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip>{source_info_dict[row.source]}</Tooltip>}
                                                >
                                                    <span className="mr-05">
                                                        <CustomBadge
                                                            key={`${row.source}-source`}
                                                            text={row.source.substring(0, 15)}
                                                            backgroundColor="#ebebeb"
                                                            textColor="black"
                                                        />
                                                    </span>
                                                </OverlayTrigger>
                                            ) : (
                                                <span className="mr-05">
                                                    <CustomBadge
                                                        key={`${row.source}-source`}
                                                        text={row.source.substring(0, 15)}
                                                        backgroundColor="#ebebeb"
                                                        textColor="black"
                                                    />
                                                </span>
                                            )}
                                            <span className="mr-05">
                                                <a href={`/region/${row.country.toLowerCase().replace(/ /g, '_')}`}>
                                                    <CustomBadge
                                                        key={`${row.source}-country`}
                                                        text={row.country}
                                                        backgroundColor="#ebebeb"
                                                        textColor="black"
                                                    />
                                                </a>
                                            </span>
                                            <span className="mr-05">
                                                <CustomBadge
                                                    key={`${row.source}-language`}
                                                    text={row.language}
                                                    backgroundColor="#ebebeb"
                                                    textColor="black"
                                                />
                                            </span>
                                        </div>
                                        <div style={{ height: '0.5rem' }}></div>
                                        <a
                                            href={row.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <h4>{row.title.replace(/\*/g, '')}</h4>
                                        </a>
                                        {timeAgo(row.date_time_published_utc)}
                                        <div style={{ height: '0.75rem' }}></div>
                                        {showSentiment && (
                                            <CustomBadge
                                                style={{ paddingLeft: '3rem' }}
                                                backgroundColor={mapSentiment(row.basic_emotion).color}
                                                textColor={mapSentiment(row.basic_emotion).textColor}
                                                text={"Tone: " + mapSentiment(row.basic_emotion).text}
                                            />
                                        )}
                                        {showCharged && row.charged == "Yes" && (
                                            <CustomBadge
                                                style={{ paddingLeft: '3rem' }}
                                                backgroundColor={mapCharged(row.charged).color}
                                                textColor={mapCharged(row.charged).textColor}
                                                text={mapCharged(row.charged).text}
                                            />
                                        )}

                                        {orgsOfInterest && (
                                            <div>
                                                <Badge bg="secondary" style={{ fontSize: '1rem', fontWeight: 'normal' }}>{findOrgInArticle(row.organizations, orgsOfInterest)}</Badge>
                                            </div>
                                        )}

                                        {personOfInterest && row.candidate_sentiment && (() => {
                                            const sentiment = getCandidateSentiment(row.candidate_sentiment, personOfInterest);
                                            const mappedSentiment = sentiment ? mapCandidateSentiment(sentiment) : null;

                                            return mappedSentiment ? (
                                                <CustomBadge
                                                    style={{ paddingLeft: '3rem' }}
                                                    backgroundColor={mappedSentiment.color}
                                                    textColor={mappedSentiment.textColor}
                                                    text={mappedSentiment.text}
                                                />
                                            ) : null;
                                        })()}

                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                    <td style={{ verticalAlign: 'bottom', textAlign: 'center' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{ position: 'relative', width: '5.5rem', height: '5.5rem', overflow: 'hidden', borderRadius: '0.5rem', marginBottom: '0.5rem' }}>
                                            <img
                                                src={row.image_link ? row.image_link : 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand'}
                                                alt="Image description"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case fallback image fails
                                                    e.target.src = 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand';
                                                }}
                                            />
                                            {row.c2pa_creds && row.c2pa_creds == "Yes" && (
                                                <img
                                                    src="https://i.imgur.com/tZTCvoD_d.webp?maxwidth=760&fidelity=grand"
                                                    alt="C2PA Icon"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0.25rem',
                                                        right: '0.25rem',
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        objectFit: 'contain'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <button
                                            onClick={() => toggleRow(index)}
                                            style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}
                                        >
                                            <FaBars style={{ fontSize: '1.75rem', color: '#222222' }} />
                                            <div style={{ height: '0.75rem' }}></div>
                                        </button>
                                    </td>
                                </tr>
                                {isExpanded && (
                                    <React.Fragment>
                                        <tr>
                                            <td colSpan={2}>
                                                <div>
                                                    <i style={{ fontSize: "1rem" }}>Published {getDatePublished(row.date_time_published_utc)}, {getTimePublished(row.date_time_published_utc)} ({getTimezoneName(row.date_time_published_utc)})</i>
                                                    <span style={{ whiteSpace: "pre-line" }}>
                                                        <div style={{ height: '0.75rem' }}></div>
                                                        {row.summary.replace(/^- /gm, "\u2022 ").replace(/\*/g, '').replace(/^\s*$(?:\r\n?|\n)/gm, '')}
                                                        {personOfInterest && (
                                                            <div>
                                                                <div style={{ height: '0.75rem' }}></div>
                                                                <i><div dangerouslySetInnerHTML={{ __html: extractPersonSnippet(row.text, personOfInterest) }} /></i>
                                                            </div>
                                                        )}
                                                        {orgsOfInterest && (
                                                            <div>
                                                                <div style={{ height: '0.75rem' }}></div>
                                                                <i><div dangerouslySetInnerHTML={{ __html: extractOrgSnippetFromListOfOrgs(row.text, orgsOfInterest) }} /></i>
                                                            </div>
                                                        )}
                                                    </span>
                                                </div>
                                                <div style={{ height: '0.75rem' }}></div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                                    {showCluster && row.cluster_id ? (
                                                        <a href={`/narrative/${row.cluster_id}`}>
                                                            <CustomBadge text={"Track Narrative"} />
                                                        </a>
                                                    ) : (
                                                        <div></div> // Empty div to keep FiShare at the bottom right
                                                    )}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {user && (
                                                            <div onClick={() => toggleFavorite(row)} style={{ marginRight: '2rem' }}>
                                                                {userData && userData.savedSpeeches.includes(row.link) ? (
                                                                    <FaBookmark color="#F6C333" size="1.5rem" />
                                                                ) : (
                                                                    <FaRegBookmark size="1.5rem" />
                                                                )}
                                                            </div>
                                                        )}
                                                        <FiShare
                                                            style={{ fontSize: '1.5rem', color: '#222222', cursor: 'pointer' }}
                                                            onClick={() => handleShare(row)}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ height: '0.75rem' }}></div>
                                            </td>
                                        </tr>
                                    </React.Fragment>

                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );

    const renderDesktopTableBeta = () => (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <td colSpan="2" style={{ textAlign: 'right' }}>
                            <button onClick={toggleExpandAll} style={{ background: 'none', border: 'none', padding: '0', cursor: 'pointer', fontSize: '1.5rem', color: '#222222' }}>
                                {expandAll ? <HiMiniBarsArrowUp fontSize="1.5rem" /> : <HiMiniBarsArrowDown fontSize="1.5rem" />}
                            </button>
                        </td>
                    </tr>
                    {filteredData.map((row, index) => {
                        const isExpanded = expandedRows.includes(index);
                        return (
                            <React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div>
                                            <div>
                                                {source_info_dict[row.source] && source_info_dict[row.source].trim() !== '' ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip>{source_info_dict[row.source]}</Tooltip>}
                                                    >
                                                        <span className="mr-05">
                                                            <CustomBadge
                                                                key={`${row.source}-source`}
                                                                text={row.source}
                                                                backgroundColor="#ebebeb"
                                                                textColor="black"
                                                            />
                                                        </span>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <span className="mr-05">
                                                        <CustomBadge
                                                            key={`${row.source}-source`}
                                                            text={row.source}
                                                            backgroundColor="#ebebeb"
                                                            textColor="black"
                                                        />
                                                    </span>
                                                )}
                                                <span className="mr-05">
                                                    <a href={`/region/${row.country.toLowerCase().replace(/ /g, '_')}`}>
                                                        <CustomBadge
                                                            key={`${row.source}-country`}
                                                            text={row.country}
                                                            backgroundColor="#ebebeb"
                                                            textColor="black"
                                                        />
                                                    </a>
                                                </span>
                                                <span className="mr-05">
                                                    <CustomBadge
                                                        key={`${row.source}-language`}
                                                        text={row.language}
                                                        backgroundColor="#ebebeb"
                                                        textColor="black"
                                                    />
                                                </span>
                                            </div>
                                            <a
                                                href={row.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                <div>
                                                    <div style={{ height: '0.5rem' }}></div>
                                                    <h4>
                                                        {row.title
                                                            .replace(/\*/g, '')  // Remove any asterisks
                                                            .replace(/([a-z])([A-Z])/g, '$1 $2')}  {/* Insert a space between lowercase and uppercase transitions */}
                                                    </h4>
                                                    {timeAgo(row.date_time_published_utc)}
                                                </div>
                                            </a>
                                        </div>
                                        <div style={{ height: '0.75rem' }}></div>
                                        {showSentiment && (
                                            <div>
                                                <CustomBadge
                                                    style={{ paddingLeft: '3rem' }}
                                                    backgroundColor={mapSentiment(row.basic_emotion).color}
                                                    textColor={mapSentiment(row.basic_emotion).textColor}
                                                    text={"Tone: " + mapSentiment(row.basic_emotion).text}
                                                />
                                            </div>
                                        )}

                                        {showCharged && row.charged == "Yes" && (
                                            <CustomBadge
                                                style={{ paddingLeft: '3rem' }}
                                                backgroundColor={mapCharged(row.charged).color}
                                                textColor={mapCharged(row.charged).textColor}
                                                text={mapCharged(row.charged).text}
                                            />
                                        )}
                                        {orgsOfInterest && (
                                            <div>
                                                <Badge bg="secondary" style={{ fontSize: '1rem', fontWeight: 'normal' }}>{findOrgInArticle(row.organizations, orgsOfInterest)}</Badge>
                                            </div>
                                        )}

                                        {personOfInterest && row.candidate_sentiment && (() => {
                                            const sentiment = getCandidateSentiment(row.candidate_sentiment, personOfInterest);
                                            const mappedSentiment = sentiment ? mapCandidateSentiment(sentiment) : null;
                                            return mappedSentiment ? (
                                                <CustomBadge
                                                    style={{ paddingLeft: '3rem' }}
                                                    backgroundColor={mappedSentiment.color}
                                                    textColor={mappedSentiment.textColor}
                                                    text={mappedSentiment.text}
                                                />
                                            ) : null;
                                        })()}

                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                                            {/* {showCluster && row.cluster_id ? (
                                                <a href={`/narrative/${row.cluster_id}`}>
                                                    <CustomBadge text={"Track Narrative"} />
                                                </a>
                                            ) : (
                                                <div style={{ width: '10rem' }}></div> // Empty div to keep FiShare at the bottom right
                                            )} */}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-top-summary">See Summary</Tooltip>}>
                                                    <button
                                                        onClick={() => toggleRow(index)}
                                                        style={{ background: 'none', border: 'none', textDecoration: 'none', color: '#222222' }}
                                                    >
                                                        <FaBars style={{ fontSize: '1.5rem', color: '#222222', marginRight: '2rem' }} />
                                                    </button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top-copy">Copy Link</Tooltip>}>
                                                    <span>
                                                        <FaRegClone
                                                            style={{
                                                                fontSize: copiedIndex === index ? '1.6rem' : '1.5rem',
                                                                cursor: 'pointer',
                                                                marginRight: '2rem'
                                                            }}
                                                            onClick={() => handleCopy(row.article_id, index)}
                                                        />
                                                    </span>
                                                </OverlayTrigger>
                                                {user && (
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-top-bookmark">Bookmark Article</Tooltip>}>
                                                        <div onClick={() => toggleFavorite(row)}>
                                                            {userData && userData.savedSpeeches.includes(row.link) ? (
                                                                <FaBookmark color="#F6C333" size="1.5rem" />
                                                            ) : (
                                                                <FaRegBookmark size="1.5rem" />
                                                            )}
                                                        </div>
                                                    </OverlayTrigger>
                                                )}

                                            </div>
                                        </div>
                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                    <td style={{ verticalAlign: 'bottom', textAlign: 'center' }}>
                                        <div style={{ height: '0.75rem' }}></div>
                                        <div style={{
                                            position: 'relative',
                                            width: '8rem',
                                            height: '8rem',
                                            overflow: 'hidden',
                                            borderRadius: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}>
                                            <img
                                                src={row.image_link ? row.image_link : 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand'}
                                                alt="Image description"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop in case fallback image fails
                                                    e.target.src = 'https://i.imgur.com/iioqaDS_d.webp?maxwidth=760&fidelity=grand';
                                                }}
                                            />
                                            {row.c2pa_creds && row.c2pa_creds == "Yes" && (
                                                <img
                                                    src="https://i.imgur.com/tZTCvoD_d.webp?maxwidth=760&fidelity=grand"
                                                    alt="C2PA Icon"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '0.25rem',
                                                        right: '0.25rem',
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        objectFit: 'contain'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ height: '0.75rem' }}></div>
                                    </td>
                                </tr>
                                {
                                    isExpanded && (
                                        <React.Fragment>
                                            <tr>
                                                <td colSpan={2}>
                                                    <i style={{ fontSize: "1rem" }}>Published {getDatePublished(row.date_time_published_utc)}, {getTimePublished(row.date_time_published_utc)} ({getTimezoneName(row.date_time_published_utc)})</i>
                                                    <div>
                                                        <span style={{ whiteSpace: "pre-line" }}>
                                                            <div style={{ height: '0.75rem' }}></div>
                                                            {row.summary.replace(/^- /gm, "\u2022 ").replace(/\*/g, '').replace(/^\s*$(?:\r\n?|\n)/gm, '')}
                                                        </span>
                                                    </div>
                                                    <div style={{ height: '0.75rem' }}></div>
                                                    {personOfInterest && (
                                                        <i><div dangerouslySetInnerHTML={{ __html: extractPersonSnippet(row.text, personOfInterest) }} /></i>
                                                    )}
                                                    {orgsOfInterest && (
                                                        <i><div dangerouslySetInnerHTML={{ __html: extractOrgSnippetFromListOfOrgs(row.text, orgsOfInterest) }} /></i>
                                                    )}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </div >
    );



    return (
        <div>
            {isMobile ? renderMobileTableBeta() : renderDesktopTableBeta()}
        </div>
    );
}

export default DataTable;
